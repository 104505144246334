import { UniqueIdentifier } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'

import type { Containers, FlattenedContainers, FlattenedItem, TreeItem, TreeItems } from './types'

function getDragDepth(offset: number, indentationWidth: number) {
  return Math.round(offset / indentationWidth)
}

export const getProjection = <T>(
  items: FlattenedItem<T>[],
  activeId: UniqueIdentifier | undefined,
  overId: UniqueIdentifier | undefined,
  dragOffset: number,
  indentationWidth: number
) => {
  if (!activeId || !overId) return
  const overItemIndex = items.findIndex(({ id }) => id === overId)
  const activeItemIndex = items.findIndex(({ id }) => id === activeId)
  const activeItem = items[activeItemIndex]
  const newItems = arrayMove(items, activeItemIndex, overItemIndex)
  const previousItem = newItems[overItemIndex - 1]
  const nextItem = newItems[overItemIndex + 1]
  const dragDepth = getDragDepth(dragOffset, indentationWidth)
  const projectedDepth = activeItem.depth + dragDepth
  let depth = projectedDepth
  let parent = findParentWithDepth(depth - 1, previousItem)
  parent = findParentWhichCanHaveChildren(parent)

  if (parent?.collapsed) parent = null

  const maxDepth = (parent ? parent.depth : previousItem ? previousItem.depth - 1 : -1) + 1

  const minDepth = nextItem?.depth ?? 0

  if (projectedDepth > maxDepth) {
    depth = maxDepth
  } else if (projectedDepth < minDepth) {
    depth = minDepth
  }

  const isLast = (nextItem?.depth ?? -1) < depth
  const parentId = getParentId()

  return {
    depth,
    parentId: parentId,
    parent: items.find(item => item.id === parentId),
    isLast,
  }

  function findParentWithDepth(depth: number, previousItem: FlattenedItem<T>) {
    if (!previousItem) return null
    while (depth < previousItem.depth) {
      if (previousItem.parent === null) return null
      previousItem = previousItem.parent
    }
    return previousItem
  }
  function findParentWhichCanHaveChildren(parent: FlattenedItem<T> | null): FlattenedItem<T> | null {
    if (!parent) return parent
    const canHaveChildren = parent.canHaveChildren
    if (canHaveChildren === false) return findParentWhichCanHaveChildren(parent.parent)
    return parent
  }

  function getParentId() {
    if (depth === 0 || !previousItem) {
      return null
    }

    if (previousItem.canHaveChildren) {
      if (depth === previousItem.depth) {
        return previousItem.parentId
      }

      if (depth > previousItem.depth) {
        return previousItem.id
      }
    }

    const newParent = newItems
      .slice(0, overItemIndex)
      .reverse()
      .find(item => item.depth === depth)?.parentId

    return newParent ?? null
  }
}

const flatten = <T extends Record<string, any>>(
  items: TreeItems<T>,
  parentId: string | null = null,
  depth = 0,
  parent: FlattenedItem<T> | null = null
): FlattenedItem<T>[] => {
  return items.reduce<FlattenedItem<T>[]>((acc, item, index) => {
    const flattenedItem: FlattenedItem<T> = {
      ...item,
      parentId,
      depth,
      index,
      isLast: items.length === index + 1,
      parent: parent,
    }
    return [...acc, flattenedItem, ...flatten(item.children ?? [], item.id, depth + 1, flattenedItem)]
  }, [])
}

export const flattenTree = <T extends Record<string, any>>(items: TreeItems<T>): FlattenedItem<T>[] => {
  return flatten(items)
}

export const buildTree = <T extends Record<string, any>>(flattenedItems: FlattenedItem<T>[]): TreeItems<T> => {
  const root: TreeItem<T> = { id: 'root', children: [] } as any
  const nodes: Record<string, TreeItem<T>> = { [root.id]: root }
  const items = flattenedItems.map(item => ({ ...item, children: [] }))

  for (const item of items) {
    const { id } = item
    const parentId = item.parentId ?? root.id
    const parent = nodes[parentId] ?? findItem(items, parentId)

    nodes[id] = item
    parent?.children?.push(item)
  }

  return root.children ?? []
}

export const findItem = <T>(items: TreeItem<T>[], itemId: UniqueIdentifier) => {
  return items.find(({ id }) => id === itemId)
}

export const findItemDeep = <T>(items: TreeItem<T>[], itemId: UniqueIdentifier): TreeItem<T> | undefined => {
  for (const item of items) {
    const { id, children } = item

    if (id === itemId) {
      return item
    }

    if (children?.length) {
      const child = findItemDeep(children, itemId)

      if (child) {
        return child
      }
    }
  }

  return undefined
}

export const setProperty = <TData extends Record<string, any>, P extends keyof TData>(
  items: TreeItem<TData>[],
  id: string,
  property: P,
  setter: (value: TData[P]) => TData[P]
) => {
  const clonedItems = [...items]
  for (let i = 0; i < clonedItems.length; i++) {
    const item = clonedItems[i]

    if (item.id === id) {
      clonedItems[i] = { ...item, [property]: setter(item[property]) }
      continue
    }

    if (item.children?.length) {
      clonedItems[i] = { ...item, children: setProperty(item.children, id, property, setter) }
    }
  }

  return clonedItems
}

const updateChildren = <T>(items: TreeItem<T>[], id: string, updater: (items: TreeItem<T>[]) => TreeItem<T>[]) => {
  return setProperty(items, id, 'children', updater)
}

export const isAncestor = <T>(id: UniqueIdentifier, item: FlattenedItem<T>): boolean => {
  if (!item.parent) return false

  return item.parent.id === id || isAncestor(id, item.parent)
}

export const hasCollapsedParent = <T>(item: FlattenedItem<T>): boolean => {
  if (!item.parent) return false

  return item.parent.collapsed || hasCollapsedParent(item.parent)
}

export const moveItemInSameContainer = <TData extends Record<string, any>>(
  containers: Containers<TData>,
  flattenedContainers: FlattenedContainers<TData>,
  containerId: UniqueIdentifier,
  activeId: UniqueIdentifier,
  overId: UniqueIdentifier,
  projected?: ReturnType<typeof getProjection<TData>>
) => {
  const activeItem = flattenedContainers[containerId].find(item => item.id === activeId)
  const overItem = flattenedContainers[containerId].find(item => item.id === overId)

  if (!activeItem) return containers

  const sourceParentId = activeItem.parentId
  const destinationParentId = projected?.parentId

  const absoluteActiveIndex = flattenedContainers[containerId].findIndex(item => item.id === activeId)
  const absoluteOverIndex = flattenedContainers[containerId].findIndex(item => item.id === overId)
  const modifier = absoluteActiveIndex <= absoluteOverIndex ? 1 : 0

  if (sourceParentId && sourceParentId === destinationParentId) {
    return {
      ...containers,
      [containerId]: updateChildren(containers[containerId], sourceParentId, items => {
        const overIndex = items?.findIndex(item => item.id === overId)
        const activeIndex = items?.findIndex(item => item.id === activeId)

        return arrayMove(items, activeIndex, overIndex)
      }),
    }
  } else if (sourceParentId == null && destinationParentId == null) {
    const overIndex = containers[containerId].findIndex(item => item.id === overId)
    const activeIndex = containers[containerId].findIndex(item => item.id === activeId)

    return {
      ...containers,
      [containerId]: arrayMove(containers[containerId], activeIndex, overIndex),
    }
  } else if (sourceParentId && destinationParentId && sourceParentId !== destinationParentId) {
    const containerWithoutActiveItem = updateChildren(containers[containerId], sourceParentId, items => {
      return items?.filter(item => item.id !== activeId)
    })
    const item = findItemDeep(containers[containerId], activeId)

    if (!item) return containers

    const parentIsCollapsed = findItemDeep(containers[containerId], destinationParentId)?.collapsed
    return {
      ...containers,
      [containerId]: updateChildren(containerWithoutActiveItem, destinationParentId, items => {
        if (!items) return []
        let overIndex = parentIsCollapsed ? items?.length : items?.findIndex(item => item.id === overId) + modifier
        if (overIndex === -1) overIndex = items?.length
        return [...items.slice(0, overIndex), item, ...items.slice(overIndex, items.length)]
      }),
    }
  } else if (!sourceParentId && destinationParentId) {
    const containerWithoutActiveItem = containers[containerId].filter(item => item.id !== activeId)

    const item = findItemDeep(containers[containerId], activeId)

    if (!item) return containers

    const parentIsCollapsed = findItemDeep(containers[containerId], destinationParentId)?.collapsed

    return {
      ...containers,
      [containerId]: updateChildren(containerWithoutActiveItem, destinationParentId, items => {
        const overIndex =
          parentIsCollapsed || activeId === overId
            ? items.length
            : items.findIndex(item => item.id === overId) + modifier

        return [...items.slice(0, overIndex), item, ...items.slice(overIndex, items.length)]
      }),
    }
  } else if (sourceParentId && !destinationParentId) {
    const item = findItemDeep(containers[containerId], activeId)

    if (!item) return containers

    const containerWithoutActiveItem = updateChildren(containers[containerId], sourceParentId, items => {
      return items?.filter(item => item.id !== activeId)
    })

    const overIndex = overItem?.parent
      ? overItem?.parent.index + 1
      : containerWithoutActiveItem.findIndex(item => item.id === overId)

    return {
      ...containers,
      [containerId]: [
        ...containerWithoutActiveItem.slice(0, overIndex),
        item,
        ...containerWithoutActiveItem.slice(overIndex, containerWithoutActiveItem.length),
      ],
    }
  }

  return containers
}

export const moveItemToNewContainer = <TData extends Record<string, any>>(
  containers: Containers<TData>,
  flattenedContainers: FlattenedContainers<TData>,
  sourceContainerId: UniqueIdentifier,
  destinationContainerId: UniqueIdentifier,
  activeId: UniqueIdentifier,
  overId: UniqueIdentifier,
  projected?: ReturnType<typeof getProjection<TData>>,
  positionStrategy: 'replace' | 'append' = 'replace'
) => {
  const activeItem = flattenedContainers[sourceContainerId].find(item => item.id === activeId)
  const overItem = flattenedContainers[destinationContainerId].find(item => item.id === overId)

  if (!activeItem) return containers

  const sourceParentId = activeItem.parentId
  const destinationParentId = projected?.parentId

  const absoluteActiveIndex = flattenedContainers[destinationContainerId].findIndex(item => item.id === activeId)
  const absoluteOverIndex = flattenedContainers[sourceContainerId].findIndex(item => item.id === overId)
  const modifier = absoluteActiveIndex < absoluteOverIndex ? 1 : 0

  const sourceContainer = containers[sourceContainerId]
  const destinationContainer = containers[destinationContainerId]

  if (sourceParentId == null && destinationParentId == null) {
    const overIndex = overItem?.parent
      ? overItem?.parent.index + 1
      : destinationContainer.findIndex(item => item.id === overId) + (positionStrategy === 'append' ? 1 : 0)

    return {
      ...containers,
      [sourceContainerId]: sourceContainer.filter(item => item.id !== activeId),
      [destinationContainerId]: [
        ...destinationContainer.slice(0, overIndex),
        activeItem,
        ...destinationContainer.slice(overIndex),
      ],
    }
  } else if (sourceParentId && destinationParentId && sourceParentId !== destinationParentId) {
    const containerWithoutActiveItem = updateChildren(sourceContainer, sourceParentId, items => {
      return items?.filter(item => item.id !== activeId) ?? []
    })

    const item = findItemDeep(sourceContainer, activeId)

    if (!item) return containers

    const parentIsCollapsed = findItemDeep(destinationContainer, destinationParentId)?.collapsed
    return {
      ...containers,
      [destinationContainerId]: updateChildren(containerWithoutActiveItem, destinationParentId, items => {
        const overIndex = parentIsCollapsed ? items?.length : items!.findIndex(item => item.id === overId) + modifier
        return [...items!.slice(0, overIndex), item, ...items!.slice(overIndex, items!.length)]
      }),
    }
  } else if (!sourceParentId && destinationParentId) {
    const parentIsCollapsed = findItemDeep(destinationContainer, destinationParentId)?.collapsed

    return {
      ...containers,
      [sourceContainerId]: sourceContainer.filter(item => item.id !== activeId),
      [destinationContainerId]: updateChildren(destinationContainer, destinationParentId, items => {
        const overIndex = parentIsCollapsed ? items?.length : items!.findIndex(item => item.id === overId) + modifier
        return [...items!.slice(0, overIndex), activeItem, ...items!.slice(overIndex, items!.length)]
      }),
    }
  } else if (sourceParentId && !destinationParentId) {
    const item = findItemDeep(sourceContainer, activeId)

    if (!item) return containers

    const containerWithoutActiveItem = updateChildren(sourceContainer, sourceParentId, items => {
      return items?.filter(item => item.id !== activeId)
    })

    const overIndex = overItem?.parent
      ? overItem?.parent.index + 1
      : destinationContainer.findIndex(item => item.id === overId)

    return {
      ...containers,
      [sourceContainerId]: containerWithoutActiveItem,
      [destinationContainerId]: [
        ...destinationContainer.slice(0, overIndex),
        item,
        ...destinationContainer.slice(overIndex, containerWithoutActiveItem.length),
      ],
    }
  }

  return containers
}

export const moveItem = <T extends Record<string, any>>(
  containers: Containers<T>,
  flattenedContainers: FlattenedContainers<T>,
  sourceContainerId: UniqueIdentifier,
  destinationContainerId: UniqueIdentifier,
  activeId: UniqueIdentifier,
  overId: UniqueIdentifier,
  projected?: ReturnType<typeof getProjection<T>>,
  positionStrategy?: 'replace' | 'append'
) => {
  if (sourceContainerId === destinationContainerId)
    return moveItemInSameContainer(
      containers,
      flattenedContainers,
      sourceContainerId,
      activeId,
      overId,
      projected
    ) as Containers<T>

  return moveItemToNewContainer(
    containers,
    flattenedContainers,
    sourceContainerId,
    destinationContainerId,
    activeId,
    overId,
    projected,
    positionStrategy
  ) as Containers<T>
}
