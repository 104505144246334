import { debounce, flatten } from 'lodash'

import { NumberFilterValue } from 'common/components'

import { InventoryDataTable } from '../../types/dataTable'

const useVariantsFilter = (dataTable: InventoryDataTable) => {
  const stockFilter = dataTable.stock
  const setStockFilter = (value?: NumberFilterValue) => {
    dataTable.setFilter('stock', value)
  }

  const searchFilter = dataTable.search
  const setSearchFilter = (value?: string) => dataTable.setSearch(value)

  const answersFilters = dataTable.questionAnswers
  const setActiveAnswersFilters = (value?: { [questionId: string]: string[] }) =>
    dataTable.setFilter('questionAnswers', value)

  const statusFilters =
    dataTable.continueSelling == null
      ? []
      : dataTable.continueSelling === true
      ? ['continue-selling']
      : ['stop-selling']
  const setActiveStatusFilters = (value?: boolean) => dataTable.setFilter('continueSelling', value)

  const toggleAnswersFilters = (questionId: string, answerId: string): void => {
    if (answersFilters?.[questionId]?.includes(answerId)) {
      if (answersFilters[questionId].length === 1) {
        const updatedFilters = { ...answersFilters }

        delete updatedFilters[questionId]
        if (Object.keys(updatedFilters).length === 0) return setActiveAnswersFilters(undefined)

        return setActiveAnswersFilters(updatedFilters)
      }

      return setActiveAnswersFilters({
        ...answersFilters,
        [questionId]: answersFilters[questionId].filter(existingAnswerId => existingAnswerId !== answerId),
      })
    }

    return setActiveAnswersFilters({
      ...answersFilters,
      [questionId]: [...(answersFilters?.[questionId] ? answersFilters[questionId] : []), answerId],
    })
  }

  const clearAnswersFilters = (questionId: string): void => {
    const updatedFilters = { ...answersFilters }

    delete updatedFilters[questionId]
    if (Object.keys(updatedFilters).length === 0) return setActiveAnswersFilters(undefined)

    return setActiveAnswersFilters(updatedFilters)
  }

  const clearAllAnswerFilters = (): void => {
    return setActiveAnswersFilters(undefined)
  }

  const toggleStatusFilters = (status: string): void => {
    if (status === 'continue-selling')
      return setActiveStatusFilters(statusFilters.includes('continue-selling') ? undefined : true)
    if (status === 'stop-selling')
      return setActiveStatusFilters(statusFilters.includes('stop-selling') ? undefined : false)
  }

  const clearStatusFilters = (): void => {
    return setActiveStatusFilters(undefined)
  }

  const clearStockFilter = () => setStockFilter(undefined)

  const clearSearchFilter = () => setSearchFilter('')

  const clearAllFilters = () => {
    clearAllAnswerFilters()
    clearStatusFilters()
    clearSearchFilter()
    clearStockFilter()
  }

  const areFiltersEmpty =
    searchFilter === '' &&
    (answersFilters == null || flatten(Object.values(answersFilters)).length === 0) &&
    statusFilters.length === 0 &&
    stockFilter === undefined

  return {
    setSearchFilter,
    setStockFilter: debounce(setStockFilter, 500),
    clearAnswersFilters,
    toggleAnswersFilters,
    toggleStatusFilters,
    clearStatusFilters,
    areFiltersEmpty,
    clearAllFilters,
    clearStockFilter,
    clearSearchFilter,
    searchFilter,
    answersFilters,
    statusFilters,
    stockFilter,
  }
}

export default useVariantsFilter
