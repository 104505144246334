import { EntityType, QuestionInputType } from '@packages/types'
import { createSelector } from 'reselect'

import { twoDDisplayerSelectors } from 'customizer/2dDisplayer'
import { bulkOrderGroupSelector } from 'customizer/bulkOrder/selectors'
import { selectors as customizationSelectors, utils as customizationUtils } from 'customizer/customization'
import { selectors as stocksSelectors } from 'customizer/stocks'

import { flattenFolders, flattenChildren, getAvailableSteps, getFirstAvailableStep } from './utils'

export const getComponentKey = ({ componentKey = 'default' }) => componentKey

export const showInteractionRequiredWarningsSelector = state => state.questionPanel.showInteractionRequiredWarnings

export const showSubmitErrorSelector = state => state.questionPanel.showSubmitError

export const rootStepSelector = createSelector(customizationSelectors.customizerProductSelector, ({ tree }) => tree)

export const treeEntitySelector = createSelector(
  customizationSelectors.configurationSelector,
  customizationSelectors.groupsSelector,
  (_, { id }) => ({ id }),
  (questions, groups, { id }) => {
    return [...questions, ...groups].find(entity => entity.id === id)
  }
)

export const currentStepSelector = state => {
  const { currentStep } = state.questionPanel
  const bulkOrderGroup = bulkOrderGroupSelector(state)
  const isSelectedStepBulkOrder = bulkOrderGroup && currentStep === bulkOrderGroup.id
  const isSelectedStepInBulkOrder = !!bulkOrderGroup?.children.some(child => child === currentStep)

  const currentStepId = isSelectedStepBulkOrder || isSelectedStepInBulkOrder ? bulkOrderGroup.id : currentStep

  return treeEntitySelector(state, { id: currentStepId })
}

export const currentStepTitleSelector = createSelector(currentStepSelector, step => step?.name)

const getRequiredQuestions = steps => {
  return steps
    .reduce((sum, step) => {
      const { children, entityType, isRequired, minSelections } = step
      if (entityType === EntityType.Question) {
        if (isRequired || minSelections) return [...sum, step]

        return sum
      }

      return [...sum, ...getRequiredQuestions(children)]
    }, [])
    .filter(step => customizationUtils.stepHasQuestionWithAvailableAnswers(step))
}

const getOutOfStockSteps = (steps, state) => {
  return steps.reduce((sum, step) => {
    const { children, entityType } = step

    if (entityType === EntityType.Question) {
      const isOutOfStock = stocksSelectors.isQuestionOutOfStockSelector(state, step.id)

      if (isOutOfStock) return [...sum, step]

      return sum
    }

    return [...sum, ...getOutOfStockSteps(children, state)]
  }, [])
}

const getQuestionsOverAvailableStocks = (steps, state) => {
  return steps.reduce((sum, step) => {
    const { children, entityType } = step

    if (entityType === EntityType.Question) {
      const isOverAvailableStock = stocksSelectors.isQuestionOverAvailableStocksSelector(state, step.id)

      if (isOverAvailableStock) return [...sum, step]

      return sum
    }

    return [...sum, ...getQuestionsOverAvailableStocks(children, state)]
  }, [])
}

export const isRequiredQuestionValid = question => {
  const { selectedAnswer, selectedAnswers, inputType, isRequired, minSelections, isMultiAnswer } = question
  if (isRequired && customizationUtils.questionHasAvailableAnswers(question)) {
    return !!(inputType === QuestionInputType.Text ? selectedAnswer?.value : selectedAnswer)
  }
  if (isMultiAnswer && minSelections) return selectedAnswers.length >= minSelections
  return true
}

const interactionRequiredQuestionHadInteraction = question => {
  return question.isInteractionRequired ? question.hadInteraction : true
}

export const shouldDisplayOutOfStockErrorSelector = (state, questionId) => {
  if (!showInteractionRequiredWarningsSelector(state)) return false

  return stocksSelectors.isQuestionOutOfStockSelector(state, questionId)
}

export const shouldDisplayRequiredErrorSelector = (state, questionId) => {
  if (!showInteractionRequiredWarningsSelector(state)) return false

  const question = customizationSelectors.configurationSelector(state).find(question => question.id === questionId)

  if (!isRequiredQuestionValid(question)) return true
  if (!interactionRequiredQuestionHadInteraction(question)) return true

  return false
}

export const shouldDisplayQuestionErrorSelector = (state, questionId) => {
  return (
    shouldDisplayRequiredErrorSelector(state, questionId) || shouldDisplayOutOfStockErrorSelector(state, questionId)
  )
}

export const shouldDisplayStepErrorSelector = (state, stepId) => {
  const question = customizationSelectors.configurationSelector(state).find(question => question.id === stepId)

  if (question != null) return shouldDisplayQuestionErrorSelector(state, question.id)

  const group = customizationSelectors.groupsSelector(state).find(group => group.id === stepId)

  return flattenChildren(group.children).some(question => shouldDisplayQuestionErrorSelector(state, question.id))
}

export const invalidQuestionsSelector = createSelector(customizationSelectors.customizerProductSelector, ({ tree }) => {
  if (!tree) return []

  return getRequiredQuestions(tree.children).filter(question => {
    return !isRequiredQuestionValid(question)
  })
})

export const outOfStockStepsSelector = createSelector(
  customizationSelectors.customizerProductSelector,
  state => state,
  ({ tree }, state) => {
    if (!tree) return []

    return getOutOfStockSteps(tree.children, state)
  }
)

export const questionsOverAvailableStocksSelector = createSelector(
  customizationSelectors.customizerProductSelector,
  state => state,
  ({ tree }, state) => {
    if (!tree) return []

    return getQuestionsOverAvailableStocks(tree.children, state)
  }
)

export const isSubmittingSelector = createSelector(
  ({ questionPanel }) => questionPanel,
  ({ isSubmitting }) => isSubmitting
)

export const productAsQuestionPanelSelector = createSelector(
  customizationSelectors.treeSelector,
  (_, { maxNestingLevel }) => ({ maxNestingLevel }),
  (tree, { maxNestingLevel = Number.MAX_SAFE_INTEGER }) => {
    if (!tree) return
    return flattenFolders(tree, maxNestingLevel)
  }
)

const nextVerticalStepSelector = (currentStepId, customizerProduct) => {
  const parentStep = customizerProduct.tree
  const steps = getAvailableSteps(parentStep)
  const index = steps.findIndex(step => customizationUtils.isAncestor(step, currentStepId))
  if (index !== steps.length - 1) {
    return steps[index + 1].id
  }
  return customizerProduct.tree.id
}

const nextHorizontalStepSelector = (currentStepId, groups, customizerProduct) => {
  let nextStep
  let stepId = currentStepId

  const step = groups.find(group => group.id === currentStepId)
  const availableSteps = step ? getAvailableSteps(step) : []

  if (availableSteps.length > 0) {
    return availableSteps[0].id
  }

  while (nextStep == null) {
    const parentStep = groups.find(group => group.children.find(step => step.id === stepId))

    const steps = getAvailableSteps(parentStep)
    const index = steps.findIndex(step => step.id === stepId)

    if (index !== steps.length - 1) {
      nextStep = getFirstAvailableStep(steps[index + 1])
    } else if (parentStep.id === customizerProduct.tree.id) {
      return parentStep.id
    }

    stepId = parentStep.id
  }

  return nextStep.id
}

export const isSubmitValidSelector = state => state.questionPanel.isSubmitValid

export const nextStepSelector = createSelector(
  currentStepSelector,
  customizationSelectors.groupsSelector,
  customizationSelectors.customizerProductSelector,
  twoDDisplayerSelectors.isMobileSelector,
  ({ id: currentStepId }, questions, customizerProduct, isMobile) => {
    return isMobile
      ? nextHorizontalStepSelector(currentStepId, questions, customizerProduct)
      : nextVerticalStepSelector(currentStepId, customizerProduct)
  }
)

export const showFinishCustomizationSelector = state => state.questionPanel.showFinishCustomization

export const disablePersonalisationsSelector = state => state.startingPoints.disablePersonalisations
