import { Product } from '@packages/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import React, { useCallback } from 'react'
import { useHistory, useRouteMatch } from 'react-router'

import { IconButton, Popover, ProductLimitReachedToast, useModal, usePopover, useToast } from 'common/components'
import { ToastType } from 'common/components/toast/types'
import { useProductService, useProductExportImport } from 'common/products'
import { ArchiveProductModal, ExportProductModal, RestoreProductModal } from 'common/products/components'
import { useCurrentUser } from 'common/users'
import NavVerticalIcon from 'icons/bold/01-Interface Essential/03-Menu/navigation-menu-vertical.svg'
import classMerge from 'utils/classMerge'

export interface ProductCardActionsProps {
  product: Product
}

const ProductCardActions = ({ product }: ProductCardActionsProps) => {
  const history = useHistory()
  const match = useRouteMatch<{ brandName: string }>()
  const baseUrl = match.params.brandName ? `/brands/${match.params.brandName}` : ''
  const previewBaseUrl = match.params.brandName ? `/brands/${match.params.brandName}` : '/admin'
  const queryClient = useQueryClient()
  const productsService = useProductService()
  const popover = usePopover({ placement: 'top-start', offsetConfig: 0 })
  const { openGenericErrorToast, openCustomToast, openToast } = useToast()
  const archiveModal = useModal()
  const exportModal = useModal()
  const restoreModal = useModal()
  const user = useCurrentUser()
  const { exportProduct } = useProductExportImport()

  const displayProductLimitToast = useCallback(
    () =>
      openCustomToast(props => (
        <ProductLimitReachedToast onUpgradePlan={() => history.push(`${baseUrl}/subscription`)} {...props} />
      )),
    [baseUrl]
  )

  const { mutate: duplicateProduct } = useMutation(() => productsService.duplicate(product.id), {
    onSuccess: () => {
      openToast(`Product "${product.name}" was successfully duplicated!`, ToastType.success)
      queryClient.invalidateQueries([...productsService.fetchAll.queryKeys, 'active'])
    },
    onError(error: AxiosError & { data?: { status: number; name: string } }) {
      if (error.data?.status == 403 && error.data?.name === 'MaximumActiveProductsException') {
        return displayProductLimitToast()
      }
      openGenericErrorToast(`Product "${product.name}" has not been duplicated.`)
    },
  })

  const { mutate: archiveProduct } = useMutation(() => productsService.update(product.id, { archived: true }), {
    onSuccess: () => {
      openToast(`Product "${product!.name}" was successfully archived!`, ToastType.success)
      queryClient.invalidateQueries(productsService.fetchAll.queryKeys)
    },
    onError() {
      openGenericErrorToast(`Product "${product.name}" has not been archived.`)
    },
  })

  const { mutate: restoreProduct } = useMutation(() => productsService.update(product.id, { archived: false }), {
    onSuccess: () => {
      openToast(`Product "${product.name}" was successfully restored`, ToastType.success)
      queryClient.invalidateQueries(productsService.fetchAll.queryKeys)
    },
    onError(error: AxiosError & { data?: { status: number; name: string } }) {
      if (error.data?.status == 403 && error.data?.name === 'MaximumActiveProductsException') {
        return displayProductLimitToast()
      }
      openGenericErrorToast(`Product "${product.name}" has not been restored.`)
    },
  })

  const onExport = async (field: 'live' | 'draft') => {
    try {
      await exportProduct(product.id, field)
    } catch (error) {
      openGenericErrorToast(`Product "${product.name}" has not been exported.`)
    }
  }

  const handleDuplicate = () => {
    popover.close()
    duplicateProduct()
  }

  const handleArchive = () => {
    popover.close()
    archiveModal.open()
  }

  const handleExport = () => {
    popover.close()
    exportModal.open()
  }

  const handleRestore = () => {
    popover.close()
    restoreModal.open()
  }

  return (
    <>
      <IconButton
        {...popover.referenceProps}
        onClick={e => {
          e.stopPropagation()
          popover.referenceProps.onClick(e)
        }}
        Icon={NavVerticalIcon}
        role="button"
        aria-label="Open product actions"
        className={classMerge('absolute right-4 fill-black p-[9px] opacity-0 group-hover:opacity-100', {
          'opacity-100': popover.isOpen,
        })}
      />
      <Popover isOpen={popover.isOpen} {...popover.floatingProps}>
        {product.archived ? (
          <Popover.Action onClick={handleRestore} aria-label="restore product">
            Restore
          </Popover.Action>
        ) : (
          <>
            <Popover.Action
              onClick={() => {
                popover.close()
                history.push(`${baseUrl}/products/${product.id}/history`)
              }}
              aria-label="version history"
            >
              Version history
            </Popover.Action>
            <Popover.Action
              onClick={() => {
                popover.close()
                window.open(`${previewBaseUrl}/preview?productId=${product.id}`, '_blank')
              }}
              aria-label="preview product"
            >
              Preview
            </Popover.Action>
            <Popover.Action onClick={handleDuplicate} aria-label="duplicate product">
              Duplicate
            </Popover.Action>
            {user.isMCZRUser && (
              <Popover.Action onClick={handleExport} aria-label="export product">
                Export
              </Popover.Action>
            )}
            <Popover.Action className="text-tertiary-red-700" onClick={handleArchive} aria-label="archive product">
              Archive
            </Popover.Action>
          </>
        )}
      </Popover>

      {archiveModal.isVisible && (
        <ArchiveProductModal
          productName={product!.name}
          onArchive={archiveProduct}
          onClose={archiveModal.close}
          {...archiveModal.modalProps}
        />
      )}

      {restoreModal.isVisible && (
        <RestoreProductModal
          productName={product.name}
          onRestore={restoreProduct}
          onClose={restoreModal.close}
          {...restoreModal.modalProps}
        />
      )}

      {exportModal.isVisible && (
        <ExportProductModal
          productName={product.name}
          hasDraft={!!product.draft}
          onExport={onExport}
          onClose={exportModal.close}
          {...exportModal.modalProps}
        />
      )}
    </>
  )
}

export default ProductCardActions
