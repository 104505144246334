import { PrintArea, PrintAreaMeasurementUnit } from '@packages/types'
import classNames from 'classnames'
import React, { useState } from 'react'

import { PatchTextInput, VerticalFields, HorizontalField } from 'builder/build/common/components'
import { useDispatch } from 'cms/hooks'
import { WithInformation, Select } from 'common/components'
import { constants } from 'common/printAreas'
import { min, max, combine } from 'utils/validators'

import { patchPrintArea, updatePrintAreaMeasurementUnit, updatePrintAreaDPI } from '../../actions'

import './PrintAreaDimensionsSection.scss'

const options = [
  {
    label: 'In',
    value: PrintAreaMeasurementUnit.Inches,
  },
  {
    label: 'Cm',
    value: PrintAreaMeasurementUnit.Centimeters,
  },
  {
    label: 'Mm',
    value: PrintAreaMeasurementUnit.Millimeters,
  },
  {
    label: 'Px',
    value: PrintAreaMeasurementUnit.Pixels,
  },
]

const MAX_AREA_IN_PIXELS = 225000000

interface PrintAreaDimensions {
  width: number
  height: number
  unit: PrintAreaMeasurementUnit
  dpi: number
  bleed: number
}

const dimensionValidator = ({ width, height, unit, dpi, bleed }: PrintAreaDimensions) => {
  if (unit === PrintAreaMeasurementUnit.Pixels) {
    return (width + 2 * bleed) * (height + 2 * bleed) < MAX_AREA_IN_PIXELS
  }

  const conversionRate = constants.CONVERSIONS_RATES_TO_INCHES[unit]
  const convertedWidth = (width * dpi) / conversionRate
  const convertedHeight = (height * dpi) / conversionRate
  const convertedBleed = (bleed * dpi) / conversionRate

  const totalConvertedWidth = convertedWidth + 2 * convertedBleed
  const totalConvertedHeight = convertedHeight + 2 * convertedBleed

  return totalConvertedWidth * totalConvertedHeight < MAX_AREA_IN_PIXELS
}

interface PrintAreaDimensionsSectionProps {
  printArea: PrintArea
}

const PrintAreaDimensionsSection = ({ printArea }: PrintAreaDimensionsSectionProps) => {
  const [shouldFlashMaxSize, setShouldFlashMaxSize] = useState(false)

  const flashMaxSize = () => {
    setShouldFlashMaxSize(true)
    setTimeout(() => setShouldFlashMaxSize(false), 600)
  }

  const dispatch = useDispatch()
  const stepByUnits = {
    [PrintAreaMeasurementUnit.Inches]: 0.125,
    [PrintAreaMeasurementUnit.Centimeters]: 0.1,
    [PrintAreaMeasurementUnit.Millimeters]: 1,
    [PrintAreaMeasurementUnit.Pixels]: 1,
  }

  const handleChange = (field: string, value: number, fieldSpecification?: string) =>
    dispatch(
      patchPrintArea(printArea, fieldSpecification ? { [fieldSpecification]: { [field]: value } } : { [field]: value })
    )

  const handleChangeMeasurementUnit = (value: PrintAreaMeasurementUnit) =>
    dispatch(updatePrintAreaMeasurementUnit(printArea.id, value))

  const handleChangeDPI = (value: number) => dispatch(updatePrintAreaDPI(printArea.id, value))

  const getDimensionValidator = (field: string) => (value?: string | number) => {
    const result = dimensionValidator({
      width: printArea.width,
      height: printArea.height,
      bleed: printArea.bleed,
      dpi: printArea.dpi,
      unit: printArea.measurementUnit,
      [field]: value,
    })

    if (!result) flashMaxSize()

    return result
  }

  return (
    <>
      <div className="panel__section">
        <VerticalFields>
          <label
            className={classNames({
              'text-error-default': shouldFlashMaxSize,
              'panel__field-label--shake': shouldFlashMaxSize,
            })}
          >
            Maximum size: {MAX_AREA_IN_PIXELS / 1000000}MP
          </label>
          <HorizontalField>
            <label>DPI</label>
            <PatchTextInput
              value={String(printArea.dpi)}
              onChange={e => handleChangeDPI(e.target.valueAsNumber)}
              disabled={printArea.measurementUnit === PrintAreaMeasurementUnit.Pixels}
              type="number"
              min="1"
              max="300"
              validator={combine(min(1), max(300), getDimensionValidator('dpi'))}
            />
          </HorizontalField>
          <HorizontalField>
            <label>Units</label>
            <Select
              className="w-full"
              value={options.find(option => option.value === printArea.measurementUnit)}
              options={options}
              onChange={option => {
                const isValid = getDimensionValidator('unit')(option?.value)

                if (isValid && option?.value) handleChangeMeasurementUnit(option.value)
              }}
            />
          </HorizontalField>
          <HorizontalField>
            <label>Width</label>
            <PatchTextInput
              value={String(printArea.width)}
              onChange={e => handleChange('width', e.target.valueAsNumber)}
              type="number"
              min="0"
              validator={combine(min(0), getDimensionValidator('width'))}
            />
          </HorizontalField>
          <HorizontalField>
            <label>Height</label>
            <PatchTextInput
              value={String(printArea.height)}
              onChange={e => handleChange('height', e.target.valueAsNumber)}
              type="number"
              min="0"
              validator={combine(min(0), getDimensionValidator('height'))}
            />
          </HorizontalField>
          <HorizontalField>
            <WithInformation tooltip="The bleed will allow your customer to extend their image beyond the print area, thereby preventing white borders on the images after trimming. Trim marks will appear on the pdf.">
              <label>Bleed</label>
            </WithInformation>
            <PatchTextInput
              value={String(printArea.bleed)}
              onChange={e => handleChange('bleed', e.target.valueAsNumber)}
              type="number"
              step={stepByUnits[printArea.measurementUnit]}
              min="0"
              validator={combine(min(0), getDimensionValidator('bleed'))}
            />
          </HorizontalField>
        </VerticalFields>
      </div>
      <hr className="panel__divider" />
      <div className="panel__section">
        <VerticalFields>
          <WithInformation tooltip="The margin safety indicates where important information should be placed so as not to be cut.">
            <label className="font-medium">Safety margin</label>
          </WithInformation>

          <HorizontalField>
            <label className="panel__field-label margin-left__auto">Width</label>
            <PatchTextInput
              value={String(printArea.margins.horizontal)}
              onChange={e => handleChange('horizontal', e.target.valueAsNumber, 'margins')}
              type="number"
              min="0"
              validator={min(0)}
            />
          </HorizontalField>
          <HorizontalField>
            <label>Height</label>
            <PatchTextInput
              value={String(printArea.margins.vertical)}
              onChange={e => handleChange('vertical', e.target.valueAsNumber, 'margins')}
              type="number"
              min="0"
              validator={min(0)}
            />
          </HorizontalField>
        </VerticalFields>
      </div>
    </>
  )
}

export default PrintAreaDimensionsSection
