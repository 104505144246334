import { PaymentStrategy, ShopifyOnlineStore } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'

import { useOnlineStoreService } from 'cms/onlineStores'
import { FullPageModal, ModalProps } from 'common/components'

import { useSubscriptionService } from '../../hooks'
import { ShopifyTotalCard, StripeActivationFeedbackCard } from '../cards'
import StripeActivationPageModal from './StripeActivationPageModal'

interface FullPageBillingPlansProps extends Omit<ModalProps, 'children'> {
  onClose: () => void
}

const ActivateCustomPlanModal = ({ onClose, ...modalProps }: FullPageBillingPlansProps) => {
  const [showStripeActivationFeedBack, setShowStripeActivationFeedBack] = useState(false)

  const subscriptionService = useSubscriptionService()
  const onlineStoreService = useOnlineStoreService()

  const { data: subscription } = useQuery(subscriptionService.fetchOne.queryKeys, subscriptionService.fetchOne)
  const { data: onlineStores } = useQuery(onlineStoreService.fetchAll.queryKeys, onlineStoreService.fetchAll, {
    initialData: [],
  })

  if (!subscription || !onlineStores) return null

  return (
    <FullPageModal className="flex" onClose={onClose} {...modalProps}>
      <div className="flex px-16 items-start">
        {subscription.paymentStrategy === PaymentStrategy.Shopify && (
          <div className="mt-4">
            <ShopifyTotalCard
              onlineStores={onlineStores as ShopifyOnlineStore[]}
              plan={subscription.plan}
              subscription={subscription}
              addons={[]}
            />
          </div>
        )}
        {subscription.paymentStrategy === PaymentStrategy.Stripe && !showStripeActivationFeedBack && (
          <StripeActivationPageModal
            plan={subscription.plan}
            addons={[]}
            onSuccess={() => setShowStripeActivationFeedBack(true)}
          />
        )}
        {subscription.paymentStrategy === PaymentStrategy.Stripe && showStripeActivationFeedBack && (
          <StripeActivationFeedbackCard />
        )}
      </div>
    </FullPageModal>
  )
}

export default ActivateCustomPlanModal
