import { CustomPlan, PlanName } from '@packages/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useFormik } from 'formik'
import React from 'react'
import * as yup from 'yup'

import {
  Modal,
  InputField,
  Button,
  Label,
  ModalProps,
  HelperText,
  NumberInput,
  Select,
  useToast,
} from 'common/components'
import { ToastType } from 'common/components/toast/types'

import { useSubscriptionService } from '../../hooks'

export interface CustomPlanModalProps extends Omit<ModalProps, 'children'> {
  onClose: () => void
  customPlanFormValues?: CustomPlan | undefined
}

export interface CreateCustomPlanFormValues {
  name: PlanName.Custom
  custom: true
  price: number
  transactionFeePercentage: number
  features: {
    productLimit: number
    whiteLabel: boolean
  }
}

const formValidation = yup.object().shape({
  price: yup.number().required('Please enter a price').noWhitespace(),
  transactionFeePercentage: yup.number().required('Please enter a transaction fee').noWhitespace(),
  features: yup.object().shape({ whiteLabel: yup.bool().required('Please choose an option') }),
  productLimit: yup.number().notRequired().noWhitespace(),
})

const whiteLabelOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]

const CreateOrUpdateCustomPlanModal = ({
  onClose,
  customPlanFormValues = undefined,
  ...modalProps
}: CustomPlanModalProps) => {
  const subscriptionService = useSubscriptionService()
  const { openToast } = useToast()
  const queryClient = useQueryClient()

  const { mutate: createCustomPlan } = useMutation(subscriptionService.createCustomPlan, {
    onSuccess: () => {
      openToast(`Custom plan successfully ${!customPlanFormValues ? 'created' : 'updated'}`, ToastType.success)
      queryClient.invalidateQueries(subscriptionService.fetchOne.queryKeys)
      onClose()
    },
  })

  const formik = useFormik<CreateCustomPlanFormValues>({
    initialValues: {
      name: PlanName.Custom,
      custom: true,
      price: customPlanFormValues?.price || 0,
      transactionFeePercentage: customPlanFormValues?.transactionFeePercentage || 0,
      features: {
        productLimit: customPlanFormValues?.features.productLimit || 0,
        whiteLabel: customPlanFormValues?.features.whiteLabel || true,
      },
    },
    onSubmit: (values, { setSubmitting }) => {
      createCustomPlan({ plan: values }, { onSettled: () => setSubmitting(false) })
    },
    validationSchema: formValidation,
  })

  const handleClose = () => {
    formik.resetForm()
    onClose()
  }

  return (
    <Modal {...modalProps}>
      <form onSubmit={formik.handleSubmit}>
        <Modal.CloseButton onClick={handleClose} />
        <Modal.Title>
          {!customPlanFormValues && <span>Create</span>}
          {!!customPlanFormValues && <span>Edit</span>} custom plan
        </Modal.Title>

        <Modal.Details>
          <div className="flex flex-col space-y-6 text-neutral-900">
            <InputField>
              <Label htmlFor="price">Monthly price</Label>
              <NumberInput
                min={0}
                id="price"
                name="price"
                placeholder="Enter monthly price"
                value={formik.values.price}
                onValueChange={value => formik.setFieldValue('price', value)}
                onBlur={formik.handleBlur}
                hasError={formik.touched.price && formik.errors.price != null}
                prefix="$ "
                allowNegative={false}
              />
              {formik.touched.price && formik.errors.price != null && (
                <HelperText hasError>{formik.errors.price}</HelperText>
              )}
            </InputField>

            <InputField>
              <Label htmlFor="transactionFeePercentage">Transaction fee</Label>
              <NumberInput
                min={0}
                id="transactionFeePercentage"
                name="transactionFeePercentage"
                placeholder="Enter transaction fee"
                value={formik.values.transactionFeePercentage}
                onValueChange={value => formik.setFieldValue('transactionFeePercentage', value)}
                onBlur={formik.handleBlur}
                hasError={formik.touched.transactionFeePercentage && formik.errors.transactionFeePercentage != null}
                increment={0.1}
                suffix=" %"
                allowNegative={false}
              />
              {formik.touched.transactionFeePercentage && formik.errors.transactionFeePercentage != null && (
                <HelperText hasError>{formik.errors.transactionFeePercentage}</HelperText>
              )}
            </InputField>

            <InputField>
              <Label htmlFor="productLimit">Product limit</Label>
              <NumberInput
                min={0}
                id="productLimit"
                name="features.productLimit"
                placeholder="Enter last product limit (leave empty for unlimited)"
                value={formik.values.features.productLimit}
                onValueChange={value => formik.setFieldValue('features.productLimit', value)}
                onBlur={formik.handleBlur}
                hasError={formik.touched.features?.productLimit && formik.errors.features?.productLimit != null}
                allowNegative={false}
              />
              {formik.touched.features?.productLimit && formik.errors.features?.productLimit != null && (
                <HelperText hasError>{formik.errors.features.productLimit}</HelperText>
              )}
            </InputField>

            <InputField>
              <Label htmlFor="whiteLabel">White label</Label>
              <Select<boolean>
                inputId="whiteLabel"
                name="features.whiteLabel"
                menuPortalTarget={document.body}
                menuPosition="fixed"
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                value={whiteLabelOptions.find(option => option.value === formik.values.features.whiteLabel)}
                options={whiteLabelOptions}
                onChange={option => {
                  formik.setFieldValue('features.whiteLabel', option!.value)
                }}
                onBlur={formik.handleBlur}
                hasError={formik.touched.features?.whiteLabel && formik.errors.features?.whiteLabel != null}
              />
              {formik.touched.features?.whiteLabel && formik.errors.features?.whiteLabel != null && (
                <HelperText hasError>{formik.errors.features.whiteLabel}</HelperText>
              )}
            </InputField>
          </div>
        </Modal.Details>

        <Modal.Actions>
          <Button type="button" variant="default" className="px-4" onClick={handleClose} disabled={formik.isSubmitting}>
            Cancel
          </Button>
          <Button
            id="create-custom-plan"
            type="submit"
            variant="primary"
            className="px-4"
            isLoading={formik.isSubmitting}
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!customPlanFormValues ? <span>Create</span> : <span>Save</span>}
          </Button>
        </Modal.Actions>
      </form>
    </Modal>
  )
}

export default CreateOrUpdateCustomPlanModal
