import { NormalizedCustomizerProduct } from '@packages/types'
import classNames from 'classnames'
import React from 'react'

import { Button, CardTabs, IconButton, Modal, Popover, useModal, usePopover } from 'common/components'
import { trpc } from 'common/hooks/trpc'
import MoreIcon from 'icons/bold/01-Interface Essential/03-Menu/navigation-menu-vertical.svg'

import { getCombinationName, getCombinationVariantsCount } from '../utils'
import NewTooltip from './NewTooltip'

export interface CombinationCardProps {
  combination: string[]
  selected: boolean
  isDeletingCombination: boolean
  customizerProduct: NormalizedCustomizerProduct
  onClick: (e: React.MouseEvent) => void
  onClickChangeQuestions: (e: React.MouseEvent) => void
  onClickDelete: (e: React.MouseEvent) => void
  productId: string
  customizerProductId: string
}

const CombinationCard = ({
  combination,
  isDeletingCombination,
  customizerProduct,
  selected,
  onClick,
  onClickChangeQuestions,
  onClickDelete,
  productId,
  customizerProductId,
}: CombinationCardProps) => {
  const popover = usePopover({
    placement: 'bottom-end',
  })

  const deleteModal = useModal()

  const withClose = (callback: (e: React.MouseEvent) => void) => (e: React.MouseEvent) => {
    callback(e)
    popover.close()
  }

  const variantsCount = getCombinationVariantsCount(combination, customizerProduct)

  const { data: newVariantsCountData } = trpc.variant.getNewCount.useQuery({
    productId: productId,
    questionIds: combination,
    customizerProductId,
  })

  const combinationName = getCombinationName(combination, customizerProduct)

  return (
    <>
      <CardTabs.Tab isSelected={selected} onClick={onClick}>
        <CardTabs.TextContainer>
          <CardTabs.PrimaryText>{combinationName}</CardTabs.PrimaryText>
          <span className="text-xs text-neutral-400">
            {variantsCount} variant{variantsCount > 1 ? 's' : ''}
            {newVariantsCountData != null && newVariantsCountData?.count > 0 && (
              <>
                ,
                <NewTooltip>
                  <span className="text-tertiary-green-400"> {newVariantsCountData.count} new</span>
                </NewTooltip>
              </>
            )}
          </span>
        </CardTabs.TextContainer>
        <div className={classNames('absolute right-2 invisible group-hover:visible', { '!visible': popover.isOpen })}>
          <IconButton {...popover.referenceProps} Icon={MoreIcon} smallIcon aria-label={`${combinationName} actions`} />
        </div>
        <Popover isOpen={popover.isOpen} {...popover.floatingProps}>
          <Popover.Action onClick={withClose(onClickChangeQuestions)}>Change questions</Popover.Action>
          <Popover.Action className="text-tertiary-red-700" onClick={withClose(deleteModal.open)}>
            Delete
          </Popover.Action>
        </Popover>
      </CardTabs.Tab>
      {deleteModal.isVisible && (
        <Modal {...deleteModal.modalProps}>
          <Modal.CloseButton onClick={deleteModal.close} />
          <Modal.Title>Delete question combination</Modal.Title>

          <Modal.Details>
            Are you sure you want to delete the combination {combinationName}? This action cannot be undone.
          </Modal.Details>

          <Modal.Actions>
            <Button
              type="button"
              variant="default"
              className="px-4"
              onClick={deleteModal.close}
              disabled={isDeletingCombination}
            >
              Cancel
            </Button>
            <Button
              type="button"
              variant="error"
              className="px-4"
              onClick={onClickDelete}
              disabled={isDeletingCombination}
              isLoading={isDeletingCombination}
            >
              Delete
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </>
  )
}

export default CombinationCard
