import {
  CustomOnlineStore,
  ECommerce,
  OnlineStore,
  PrestaShopOnlineStore,
  ShopifyOnlineStore,
  WixOnlineStore,
  WooCommerceOnlineStore,
} from '@packages/types'

export const isShopify = (onlineStore: OnlineStore): onlineStore is ShopifyOnlineStore =>
  onlineStore.eCommerce === ECommerce.Shopify

export const isPrestaShop = (onlineStore: OnlineStore): onlineStore is PrestaShopOnlineStore =>
  onlineStore.eCommerce === ECommerce.Prestashop

export const isWooCommerce = (onlineStore: OnlineStore): onlineStore is WooCommerceOnlineStore =>
  onlineStore.eCommerce === ECommerce.Woocommerce

export const isWix = (onlineStore: OnlineStore): onlineStore is WixOnlineStore =>
  onlineStore.eCommerce === ECommerce.Wix

export const isCustomStore = (onlineStore: OnlineStore): onlineStore is CustomOnlineStore =>
  onlineStore.eCommerce === ECommerce.CustomStore

export const onlineStoresHasOneOf = (onlineStores: OnlineStore[], eCommerce: ECommerce): boolean => {
  return onlineStores.find(onlineStore => onlineStore.eCommerce === eCommerce) != null
}
