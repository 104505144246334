import React from 'react'
import { StringParam, useQueryParam, withDefault } from 'use-query-params'

import { Tabs } from 'common/components'

export type ProductTabs = 'active' | 'archived'

export const TabParam = withDefault(StringParam, 'active')

const ProductsTabs = () => {
  const [tab, setTab] = useQueryParam('tab', TabParam, { updateType: 'replaceIn' })

  return (
    <Tabs>
      <Tabs.Tab onClick={() => setTab('active')} isSelected={!tab || tab == 'active'}>
        Active
      </Tabs.Tab>
      <Tabs.Tab onClick={() => setTab('archived')} isSelected={tab == 'archived'}>
        Archived
      </Tabs.Tab>
    </Tabs>
  )
}

export default ProductsTabs
