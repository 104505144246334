import { Subscription } from '@packages/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import React from 'react'

import { useSubscriptionService } from 'cms/subscription/hooks'
import { Alert, Button, useToast } from 'common/components'

interface ClientCustomPlanAlertProps {
  subscription: Subscription
}

const ClientCustomPlanAlert = ({ subscription }: ClientCustomPlanAlertProps) => {
  const { openGenericErrorToast } = useToast()
  const queryClient = useQueryClient()
  const subscriptionService = useSubscriptionService()

  const {
    mutate: acceptPlan,
    isLoading,
    data,
  } = useMutation(subscriptionService.acceptCustomPlan, {
    onSuccess: response => {
      if (response.url) return window.location.replace(response.url)

      queryClient.invalidateQueries(subscriptionService.fetchOne.queryKeys)
    },
    onError: () => openGenericErrorToast('Your plan has not been activated.'),
  })

  if (!subscription.proposedCustomPlan) return

  return (
    <Alert variant="info" aria-label="client custom plan alert" className="mt-3">
      <div className="flex-col">
        <div className="font-medium">
          A custom plan has been created for you. Haven't asked for a custom plan? Contact our support.
        </div>
        <div className="flex-col my-4 space-y-1">
          <div className="space-x-1">
            <span>Monthly fee:</span>
            <span className="font-bold">${subscription.proposedCustomPlan.price}</span>
          </div>
          <div className="space-x-1">
            <span>Transaction fee:</span>
            <span className="font-bold">{subscription.proposedCustomPlan.transactionFeePercentage}%</span>
          </div>
          <div className="space-x-1">
            <span>Product limit:</span>
            <span className="font-bold">
              {subscription.proposedCustomPlan.features.productLimit
                ? subscription.proposedCustomPlan.features.productLimit
                : 'unlimited'}
            </span>
          </div>
          <div className="space-x-1">
            <span>White label:</span>
            <span className="font-bold">
              {subscription.proposedCustomPlan.features.whiteLabel ? 'included' : 'not included'}
            </span>
          </div>
        </div>

        <div className="flex justify-end mt-2">
          <Button
            variant="subtle"
            className="mx-1"
            onClick={() => window.open('https://gokickflip.com/get-started/', '_blank')}
          >
            Contact us
          </Button>
          <Button
            variant="green"
            className="mx-1"
            onClick={() => acceptPlan()}
            isLoading={isLoading || !!data}
            disabled={isLoading || !!data}
          >
            Accept plan
          </Button>
        </div>
      </div>
    </Alert>
  )
}

export default ClientCustomPlanAlert
