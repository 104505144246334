import { PaymentStrategy, Subscription, SubscriptionStatus } from '@packages/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isFuture, isPast } from 'date-fns'
import React from 'react'

import { useSubscriptionService } from 'cms/subscription/hooks'
import { Card, Button, useModal, useToast } from 'common/components'
import { ToastType } from 'common/components/toast/types'

import { CancellationFeedbackModal, CancelSubscriptionModal } from '../../modals'
import CancelFreeTrialModal from '../../modals/CancelFreeTrialModal'

interface SubscriptionCardActionsProps {
  subscription: Subscription
  onPlanChange: () => void
  isInstalled: boolean
}

const SubscriptionCardActions = ({ subscription, onPlanChange, isInstalled }: SubscriptionCardActionsProps) => {
  const subscriptionService = useSubscriptionService()
  const { openToast, openGenericErrorToast } = useToast()
  const queryClient = useQueryClient()
  const cancelFreeTrialModal = useModal()
  const cancelSubscriptionModal = useModal()
  const cancellationFeedbackModal = useModal()

  const { mutate: continueFreeTrial, isLoading } = useMutation(
    () => subscriptionService.activate({ plan: subscription.plan, addons: subscription.addons }),
    {
      onSuccess: () => {
        openToast('Your free trial was successfully reactivated.', ToastType.success)
        queryClient.invalidateQueries(subscriptionService.fetchOne.queryKeys)
      },
      onError: () => {
        openGenericErrorToast('Your free trial was not reactivated.')
      },
    }
  )

  const isActive = subscription.status === SubscriptionStatus.Active
  const isFreeTrial = subscription.status === SubscriptionStatus.FreeTrial
  const isFreeTrialExpired = isFreeTrial && isPast(new Date(subscription.freeTrialEndsOn))
  const isCanceled = [SubscriptionStatus.Canceled, SubscriptionStatus.RequestCancel].includes(subscription.status)

  const isFreeTrialCancelled =
    isCanceled && subscription.canceledDuringTrial && isFuture(new Date(subscription.freeTrialEndsOn))

  const stripeFreeTrialActivated =
    subscription.status === SubscriptionStatus.FreeTrial &&
    subscription.paymentStrategy === PaymentStrategy.Stripe &&
    !!subscription.stripePaymentMethodId

  const isNone = subscription.paymentStrategy === PaymentStrategy.None
  const isActivateDisabled = (!isInstalled && subscription.paymentStrategy === PaymentStrategy.Shopify) || isNone

  let CardSection: React.ReactNode | null = null

  if (isFreeTrialCancelled) {
    CardSection = (
      <Card.Section className="flex justify-end py-3">
        <Button
          variant="primary"
          onClick={() => continueFreeTrial()}
          isLoading={isLoading}
          disabled={isLoading}
          aria-label="continue free trial button"
        >
          Continue free trial
        </Button>
      </Card.Section>
    )
  } else if (isFreeTrialExpired || isCanceled || subscription.status === SubscriptionStatus.Future) {
    CardSection = (
      <Card.Section className="flex justify-end py-3">
        <Button aria-label="activate now" disabled={isActivateDisabled} variant="green" onClick={onPlanChange}>
          Activate now
        </Button>
      </Card.Section>
    )
  } else if (isFreeTrial && !stripeFreeTrialActivated) {
    CardSection = (
      <Card.Section className="flex justify-between py-3">
        <Button variant="default-error" onClick={cancelFreeTrialModal.open}>
          Cancel free trial
        </Button>
        <Button aria-label="activate now" disabled={isActivateDisabled} variant="green" onClick={onPlanChange}>
          Activate now
        </Button>
      </Card.Section>
    )
  } else if (isActive || stripeFreeTrialActivated) {
    CardSection = (
      <Card.Section className="flex justify-between py-3">
        <Button variant="default-error" onClick={cancelSubscriptionModal.open}>
          Cancel subscription
        </Button>
        <Button aria-label="edit plan" disabled={isActivateDisabled} variant="default" onClick={onPlanChange}>
          Edit plan
        </Button>
      </Card.Section>
    )
  }

  return (
    <>
      {CardSection}
      {cancelFreeTrialModal.isVisible && (
        <CancelFreeTrialModal {...cancelFreeTrialModal.modalProps} onClose={cancelFreeTrialModal.close} />
      )}
      {cancelSubscriptionModal.isVisible && (
        <CancelSubscriptionModal
          {...cancelSubscriptionModal.modalProps}
          onClose={(withFeedback?: boolean) => {
            withFeedback && cancellationFeedbackModal.open()
            cancelSubscriptionModal.close()
          }}
        />
      )}
      {cancellationFeedbackModal.isVisible && (
        <CancellationFeedbackModal
          {...cancellationFeedbackModal.modalProps}
          onClose={cancellationFeedbackModal.close}
        />
      )}
    </>
  )
}

export default SubscriptionCardActions
