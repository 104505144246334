import tinycolor from 'tinycolor2'

export const reduceSettingToValues = settings => {
  return Object.keys(settings).reduce((themeSettings, key) => {
    const reducedSetting = Object.keys(settings[key].settings).reduce((setting, k) => {
      setting[k] = settings[key].settings[k].default
      return setting
    }, {})

    let customValues = settings[key].customValues
    if (customValues && customValues.fontFamily) customValues = { ...customValues, ...customValues.fontFamily }

    themeSettings[key] = {
      ...reducedSetting,
      ...(customValues ? customValues : {}),
    }

    return themeSettings
  }, {})
}

export const getDisabledColor = color => tinycolor(color).setAlpha(0.38).toHex8String()
