import { DemoProduct, OnlineStoreStatus, Product, StartingPoint } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import classNames from 'classnames'
import { orderBy } from 'lodash'
import React, { useContext, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useQueryParams, StringParam, createEnumParam } from 'use-query-params'

import useStartingPointService from 'builder/connect/hooks/useStartingPointService'
import { useOnlineStoreService } from 'cms/onlineStores'
import { useTranslationService } from 'cms/translations'
import { TopBar, Button, Select } from 'common/components'
import { useCopyToClipboard } from 'common/hooks'
import { trpc } from 'common/hooks/trpc'
import {
  CustomizerOptionsControl,
  PreviewIframe,
  PreviewModeControl,
  ProductSelectControl,
} from 'common/preview/components'
import { PreviewMode } from 'common/preview/types'
import { useProductService } from 'common/products'
import { TenantContext } from 'common/tenant'
import { twoDDisplayerActions } from 'customizer/2dDisplayer'
import LinkIcon from 'icons/bold/01-Interface Essential/27-Link-Unlink/hyperlink-2.svg'
import Skater from 'icons/custom/skater-rotate.svg'

const queryParamsConfig = {
  productId: StringParam,
  startingPointId: StringParam,
  version: createEnumParam(['live', 'draft']),
  lang: StringParam,
  shopid: StringParam,
  themeId: StringParam,
}

const CustomizerPreview = () => {
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const params = useParams<{ brandName?: string }>()
  const tenant = useContext(TenantContext)
  const [query, setQuery] = useQueryParams(queryParamsConfig, { updateType: 'replace', removeDefaultsFromUrl: true })
  const [isReady, setIsReady] = useState(false)
  const [previewMode, setPreviewMode] = useState(PreviewMode.DESKTOP)
  const copyToClipboard = useCopyToClipboard()
  const productService = useProductService()
  const translationService = useTranslationService()
  const onlineStoreService = useOnlineStoreService()
  const startingPointService = useStartingPointService()

  const { data: products, isLoading: isLoadingProducts } = useQuery(
    productService.fetchAll.queryKeys,
    () => productService.fetchAll({ params: { archived: false } }),
    {
      select: data => (data?.results || []) as Product[],
      onSuccess: data => {
        if (data.length > 0) {
          let productId = query.productId

          if (!productId) {
            productId = orderBy(data, 'updatedAt', 'desc')[0].id
          }

          let version = query.version
          const product = data.find(({ id }) => id === productId)
          const startingPointId = query.startingPointId

          if (startingPointId) {
            version = undefined
          } else if (!version) {
            version = !!product?.draft ? 'draft' : 'live'
          } else if (version === 'draft' && !product?.draft) {
            version = 'live'
          }

          setQuery({ ...query, productId, version })
        }
      },
    }
  )

  const { data: demos, isLoading: isLoadingDemos } = trpc.demoProduct.list.useQuery(
    {},
    { select: data => data?.results || [] }
  )

  const isLoading = isLoadingProducts || isLoadingDemos
  const allProducts = [...(products || []), ...(demos || [])]
  const selectedProduct = allProducts.find(({ id }) => id === query.productId)
  const isDemo = !!(selectedProduct as DemoProduct | undefined)?.demoAttributes

  const translationsQuery = useQuery(translationService.fetchAll.queryKeys, translationService.fetchAll, {
    enabled: !isDemo,
  })

  const onlineStoresQuery = useQuery(onlineStoreService.fetchAll.queryKeys, onlineStoreService.fetchAll, {
    enabled: !isDemo,
    select: data => data.filter(({ status }) => status === OnlineStoreStatus.Installed),
    initialData: [],
  })

  const { data: startingPoints, isLoading: isLoadingStartingPoints } = useQuery(
    [...startingPointService.fetchAll.queryKeys, selectedProduct?.id],
    async () => {
      const result = await startingPointService.fetchAll({
        params: {
          filter: { productId: selectedProduct!.id },
        },
      })
      return result as StartingPoint[]
    },
    {
      enabled: !isDemo,
      initialData: [],
    }
  )

  const selectedStartingPoint = startingPoints.find(({ id, isDefault }) => id === query.startingPointId && !isDefault)

  const getIFrameSrc = () => {
    const searchParams = new URLSearchParams()

    if (selectedStartingPoint) searchParams.append('startingPointId', selectedStartingPoint.id)
    if (selectedProduct && !selectedStartingPoint)
      searchParams.append('version', (selectedProduct as Product)[query.version || 'live']!)
    if (query.lang) searchParams.append('lang', query.lang)
    if (query.shopid) searchParams.append('shopid', query.shopid)
    if (params.brandName) searchParams.append('tenant', params.brandName)
    if (query.themeId) searchParams.append('themeId', query.themeId)

    if (isDemo) {
      return `${window.location.origin}/customize/demos/${query.productId}?${searchParams.toString()}`
    }

    if (selectedStartingPoint) {
      return `${window.location.origin}/customize/startingpoint/${query.startingPointId}?${searchParams.toString()}`
    }

    return `${window.location.origin}/customize/${query.productId}?${searchParams.toString()}`
  }

  const getPublicUrl = () => {
    const searchParams = new URLSearchParams()
    if (selectedProduct && query.version === 'draft') {
      searchParams.append('version', (selectedProduct as Product).draft!)
    }
    if (query.lang) searchParams.append('lang', query.lang)
    if (query.shopid) searchParams.append('shopid', query.shopid)

    const baseUrl = `${window.location.protocol}//${tenant}.${DOCUMENT_DOMAIN}`
    const queryString = Array.from(searchParams).length > 0 ? `?${searchParams.toString()}` : ''

    if (selectedStartingPoint) {
      return `${baseUrl}/customize/startingpoint/${query.startingPointId}${queryString}`
    }

    return `${baseUrl}/customize/${isDemo ? 'demos/' : ''}${query.productId}${queryString}`
  }

  const handlePreviewModeChange = (newMode: PreviewMode) => {
    setPreviewMode(newMode)

    const customizerApp = iframeRef.current?.contentWindow?.customizerApp

    if (customizerApp) {
      customizerApp.store.dispatch(twoDDisplayerActions.setIsMobile(newMode === PreviewMode.MOBILE))
    }
  }

  const handleIframeLoad = () => {
    setIsReady(true)
    handlePreviewModeChange(previewMode)
  }

  const handleProductChange = (id: string) => {
    const selectedProduct = allProducts.find(product => product.id === id)!
    const isSelectedProductADemo = !!(selectedProduct as DemoProduct).demoAttributes

    setQuery({
      ...query,
      productId: id,
      version: (selectedProduct as Product).draft ? 'draft' : 'live',
      lang: isSelectedProductADemo ? undefined : query.lang,
      shopid: isSelectedProductADemo ? undefined : query.shopid,
      startingPointId: undefined,
    })
  }

  const handleStartingPointChange = (startingPointId: string | undefined | null) => {
    const startingPoint = startingPoints.find(({ id }) => id === startingPointId)
    const selectedProduct = allProducts.find(product => product.id === startingPoint?.productId)!
    if (startingPoint?.isDefault) {
      setQuery({ ...query, version: (selectedProduct as Product).draft ? 'draft' : 'live', startingPointId: undefined })
      return
    }
    setQuery({ ...query, startingPointId, version: undefined })
  }

  if (
    isLoading ||
    (!isDemo && (translationsQuery.isLoading || onlineStoresQuery.isLoading || isLoadingStartingPoints))
  ) {
    return (
      <div className="flex flex-col w-full h-screen">
        <TopBar className="justify-between items-center px-4" />
        <div className="h-full flex flex-col items-center justify-center pb-48">
          <Skater width={40} aria-label="Skater loading" />
        </div>
      </div>
    )
  }

  const languageOptions = translationsQuery.data?.map(({ name, code }) => ({ label: name, value: code })) || []
  const storeOptions =
    onlineStoresQuery.data.map(({ id, name, currency }) => ({ label: `${name} (${currency})`, value: id })) || []
  const startingPointOptions =
    startingPoints.map(({ name, id, isDefault }) => {
      return isDefault ? { label: 'Default', value: id } : { label: name, value: id }
    }) || []

  return (
    <div className="flex flex-col w-full h-screen">
      <TopBar className="justify-between items-center px-4">
        <div className="flex flex-1">
          <PreviewModeControl
            disabled={!isReady}
            selectedMode={previewMode}
            availableModes={[PreviewMode.DESKTOP, PreviewMode.MOBILE]}
            onChange={newMode => handlePreviewModeChange(newMode)}
          />
        </div>
        <div className="flex flex-1 justify-center space-x-4">
          <ProductSelectControl
            products={allProducts}
            selectedProductId={query.productId}
            className="w-56"
            onChange={option => handleProductChange(option!.value)}
            blurInputOnSelect
            disabled={!isReady}
            isSearchable
            aria-label="Select a product"
          />
          {startingPointOptions.length > 1 && (
            <Select
              options={startingPointOptions}
              value={
                startingPointOptions.find(({ value }) => value === query.startingPointId) ||
                startingPointOptions.find(({ label }) => label === 'Default')
              }
              onChange={option => handleStartingPointChange(option?.value)}
              blurInputOnSelect
              disabled={!isReady || isDemo}
              aria-label="Select a starting point"
              className="w-56"
            />
          )}
        </div>
        <div className="flex flex-1 justify-end space-x-4">
          {storeOptions.length > 0 && (
            <Select
              options={storeOptions}
              value={storeOptions.find(({ value }) => value === query.shopid) || null}
              onChange={option => setQuery({ ...query, shopid: option?.value })}
              placeholder="Select a pricing"
              blurInputOnSelect
              isClearable
              disabled={!isReady || isDemo}
              aria-label="Select a pricing"
              className="w-1/3"
            />
          )}
          {languageOptions.length > 0 && (
            <Select
              options={languageOptions}
              value={languageOptions.find(({ value }) => value === query.lang) || null}
              onChange={option => setQuery({ ...query, lang: option?.value })}
              placeholder="Select a language"
              blurInputOnSelect
              isClearable
              disabled={!isReady || isDemo}
              aria-label="Select a language"
              className="w-1/3"
            />
          )}
          <Button
            icon={<LinkIcon className="w-3.5 fill-current text-current" />}
            onClick={() => copyToClipboard(getPublicUrl(), 'Customizer link copied')}
            disabled={!isReady}
            aria-label="Copy customizer link"
          >
            Copy link
          </Button>
          <CustomizerOptionsControl
            iframeRef={iframeRef}
            disabled={!isReady}
            selectedVersion={query.version}
            onVersionChange={version => setQuery({ ...query, version })}
            hasDraft={!!(selectedProduct as Product | undefined)?.draft}
            isVersionDisabled={isDemo || !!query.startingPointId}
          />
        </div>
      </TopBar>
      <main className="flex flex-1 bg-neutral-50 mt-[52px]">
        <PreviewIframe
          ref={iframeRef}
          src={getIFrameSrc()}
          className={classNames({ 'shadow rounded-lg': previewMode === PreviewMode.MOBILE })}
          previewMode={previewMode}
          onLoad={() => handleIframeLoad()}
          onUnload={() => setIsReady(false)}
        />
      </main>
    </div>
  )
}

export default CustomizerPreview
