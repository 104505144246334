import { FontType } from '@packages/types'
import { Field, useFormikContext } from 'formik'
import { get, merge } from 'lodash'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { SketchPicker } from 'react-color'
import tinycolor from 'tinycolor2'

import { constants as builderConstants } from 'builder/build/common'
import { getFontLabel, getFontValue } from 'common/assets'
import { AsyncFontSelect } from 'common/assets/components'
import { Input, Select, Switch, Radio, Tooltip, Popover, usePopover, useToast } from 'common/components'
import { ToastType } from 'common/components/toast/types'
import { useDocumentMoveHandlers } from 'common/hooks'
import { trpc } from 'common/hooks/trpc'
import InfoIcon from 'icons/bold/01-Interface Essential/14-Alerts/information-circle.svg'

const DescriptionTooltip = ({ setting }) => {
  return (
    <Tooltip content={setting.description} disabled={!setting.description}>
      {setting.description && <InfoIcon className="w-4 h-4 fill-neutral-500" />}
    </Tooltip>
  )
}

const ColorPicker = props => {
  const { isMoving, ...documentMoveHandlers } = useDocumentMoveHandlers()
  const presetColors = useMemo(() => props.getAllColors(props.formValues), [props])

  return (
    <div {...documentMoveHandlers}>
      <SketchPicker
        disableAlpha={!props.withAlpha}
        presetColors={presetColors}
        color={tinycolor(props.value).toRgb()}
        onChange={props.onChange}
        styles={merge(builderConstants.colorPickerStyle, {
          picker: {
            paddingLeft: '0.5rem',
            paddingRight: '0.5rem',
          },
          saturation: {
            marginLeft: '-1rem',
            marginRight: '-1rem',
            width: 'calc(100% + 2rem)',
            paddingBottom: '75%',
            position: 'relative',
            overflow: 'hidden',
          },
        })}
      />
    </div>
  )
}

ColorPicker.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  getAllColors: PropTypes.func,
  withAlpha: PropTypes.bool,
  overElement: PropTypes.shape({}),
  formValues: PropTypes.shape({}),
}

export const ColorSetting = props => {
  const popover = usePopover({ placement: 'left' })

  return (
    <div className="flex px-4 h-8 items-center" data-testid="color-setting">
      <Field name={props.fieldName}>
        {({ field, form }) => {
          const { value } = field
          const { setFieldValue, values } = form

          return (
            <>
              <label className="basis-40 flex items-center space-x-2">
                <span>{props.setting.name}</span>
                <DescriptionTooltip setting={props.setting} />
              </label>
              <div
                {...popover.referenceProps}
                className="h-6 w-6 border border-solid border-neutral-75 rounded-full shadow-xs hover:cursor-pointer hover:shadow-sm transition-shadow ml-auto"
                style={{ backgroundColor: value }}
              />
              <Popover {...popover.floatingProps} isOpen={popover.isOpen} className="w-48">
                <ColorPicker
                  formValues={values}
                  value={value}
                  getAllColors={props.getAllColors}
                  withAlpha={props.withAlpha}
                  onChange={color =>
                    setFieldValue(props.fieldName, props.withAlpha ? tinycolor(color.rgb).toRgbString() : color.hex)
                  }
                />
              </Popover>
            </>
          )
        }}
      </Field>
    </div>
  )
}

ColorSetting.propTypes = {
  setting: PropTypes.shape({ name: PropTypes.string }),
  fieldName: PropTypes.string,
  getAllColors: PropTypes.func,
  withAlpha: PropTypes.bool,
}

export const FontSetting = ({ setting, fieldName }) => {
  const trpcUtils = trpc.useContext()
  const formik = useFormikContext()
  const { openToast } = useToast()

  const { fontFamily: value, type, asset } = get(formik.values, fieldName) || {}

  const updateFontAssets = async asset => {
    formik.setFieldValue(fieldName, { fontFamily: getFontValue(asset), type: FontType.Custom, asset })

    trpcUtils.asset.listCustomFonts.invalidate()

    openToast(`${asset.originalFilename} was successfully uploaded`, ToastType.success)
  }

  return (
    <div className="flex flex-col px-4" data-testid="font-setting">
      <div className="mb-1 flex items-center space-x-2">
        <span>{setting.name}</span>
        <DescriptionTooltip setting={setting} />
      </div>
      <div className="flex w-full">
        <Field name={fieldName}>
          {({ form }) => (
            <AsyncFontSelect
              onUpload={updateFontAssets}
              onChange={({ type, asset, value }) => {
                form.setFieldValue(fieldName, { fontFamily: value, type, asset }, true)
              }}
              value={{
                value,
                type,
                asset,
                label: type === FontType.Custom ? getFontLabel(asset) : value,
              }}
              fontType={type}
              className="w-full"
              id="font-upload-popover"
            />
          )}
        </Field>
      </div>
    </div>
  )
}

FontSetting.propTypes = {
  setting: PropTypes.shape({
    name: PropTypes.string,
    default: PropTypes.object,
  }),
  googleFonts: PropTypes.arrayOf(PropTypes.shape({})),
  customFonts: PropTypes.arrayOf(PropTypes.shape({})),
  fieldName: PropTypes.string,
}

export const SelectSetting = props => {
  const { setting } = props

  return (
    <div className="flex flex-col px-4" data-testid="select-setting">
      <div className="mb-1 flex items-center space-x-2">
        <span>{setting.name}</span>
        <DescriptionTooltip setting={setting} />
      </div>
      <Field name={props.fieldName}>
        {({ field, form }) => {
          return (
            <Select
              optimize
              value={setting.options.find(option => option.value === field.value)}
              options={setting.options}
              onChange={option => {
                form.setFieldValue(props.fieldName, option.value, true)
              }}
              floatingLabel={false}
              menuPortalTarget={document.querySelector('body')}
            />
          )
        }}
      </Field>
    </div>
  )
}

SelectSetting.propTypes = {
  setting: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
  }),
  fieldName: PropTypes.string,
}

export const SizeSetting = props => {
  const { setting, fieldName } = props

  return (
    <div className="flex px-4 h-8 items-center" data-testid="size-setting">
      <div className="basis-36 shrink-0 flex items-center space-x-2">
        <span>{setting.name}</span>
        <DescriptionTooltip setting={setting} />
      </div>
      <Field name={fieldName}>
        {({ field, form }) => {
          return (
            <Input
              type="number"
              min="0"
              value={field.value?.split('px')[0]}
              onChange={e => {
                form.setFieldValue(fieldName, e.currentTarget.value < 0 ? '0px' : e.currentTarget.value + 'px', true)
              }}
              rightAddon="px"
            />
          )
        }}
      </Field>
    </div>
  )
}

SizeSetting.propTypes = {
  setting: PropTypes.shape({ name: PropTypes.string }),
  fieldName: PropTypes.string,
}

export const SwitchSetting = props => {
  const { setting, fieldName } = props

  return (
    <div className="flex px-4 h-8 items-center" data-testid="switch-setting">
      <Field name={fieldName}>
        {({ field, form }) => {
          const { value } = field
          const { setFieldValue } = form

          return (
            <>
              <div className="basis-40 shrink-0 flex items-center space-x-2">
                {setting.name}
                <DescriptionTooltip setting={setting} />
              </div>

              <Switch
                className="ml-auto"
                checked={value}
                onChange={e => setFieldValue(fieldName, e.target.checked, true)}
              />
            </>
          )
        }}
      </Field>
    </div>
  )
}

SwitchSetting.propTypes = {
  setting: PropTypes.shape({ name: PropTypes.string }),
  fieldName: PropTypes.string,
}

export const RadioSetting = props => {
  const { setting, fieldName } = props

  return (
    <Field name={fieldName}>
      {({ field, form }) => {
        const { value } = field
        const { setFieldValue } = form

        return (
          <div className="flex flex-col space-y-2">
            {setting.options.map(option => (
              <div className="flex px-4 h-8 items-center" key={option.label}>
                <div className="basis-36 shrink-0">{option.label}</div>
                <Radio
                  className="ml-auto"
                  id={option.label}
                  name={option.label}
                  checked={value === option.value}
                  onChange={() => setFieldValue(fieldName, option.value, true)}
                />
              </div>
            ))}
          </div>
        )
      }}
    </Field>
  )
}

RadioSetting.propTypes = {
  setting: PropTypes.shape({
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
  }),
  fieldName: PropTypes.string,
}

const SettingFactory = props => {
  const { setting } = props

  switch (setting.type) {
    case 'size':
      return <SizeSetting {...props} />
    case 'font':
      return <FontSetting {...props} />
    case 'select':
      return <SelectSetting {...props} />
    case 'color':
      return <ColorSetting {...props} />
    case 'colorWithAlpha':
      return <ColorSetting {...props} withAlpha />
    case 'switch':
      return <SwitchSetting {...props} />
    case 'radio':
      return <RadioSetting {...props} />
    default:
      return null
  }
}

SettingFactory.propTypes = {
  setting: PropTypes.shape({ type: PropTypes.string }),
}

const ThemeSetting = props => {
  const { sectionKey, settingKey } = props

  const fieldName = `${sectionKey}.${settingKey}`

  return <SettingFactory {...props} fieldName={fieldName} />
}

ThemeSetting.propTypes = {
  sectionKey: PropTypes.string,
  settingKey: PropTypes.string,
  setting: PropTypes.shape({ type: PropTypes.string }),
  getAllColors: PropTypes.func,
  googleFonts: PropTypes.arrayOf(PropTypes.shape({})),
  customFonts: PropTypes.arrayOf(PropTypes.shape({})),
}

export default ThemeSetting
