import React from 'react'
import { useQueryParam } from 'use-query-params'

import { BlankState, Button, Card } from 'common/components'
import { trpc } from 'common/hooks/trpc'
import SkateboardIcon from 'icons/custom/bold-skateboard.svg'

import ProductsList from '../ProductsList'
import { TabParam } from '../ProductsTabs'

const DEMO_NAMES_TO_LOAD = ['Soccer Ball', 'Sunglasses', 'PS5 Controller', 'Gaming Chair', 'Bomber Jacket', 'Watch']

export interface ProductsBlankStateProps {
  createProduct: () => void
  openDemo: () => void
}

const ProductsBlankState = ({ createProduct, openDemo }: ProductsBlankStateProps) => {
  const [tab, setTab] = useQueryParam('tab', TabParam)

  const { data, isLoading } = trpc.demoProduct.list.useQuery({
    name: DEMO_NAMES_TO_LOAD,
  })

  return (
    <div className="flex flex-col items-start justify-center pt-16 space-y-16">
      <BlankState className="w-full md:w-[400px] self-center">
        <BlankState.Icon Icon={SkateboardIcon} className="text-neutral-300" />
        {tab === 'archived' && (
          <>
            <BlankState.Title>There are no archived products, yet</BlankState.Title>
            <BlankState.Details>
              Once you archive products they will be shown here so you can restore them.
            </BlankState.Details>
            <BlankState.Action onClick={() => setTab('active')}>Active products</BlankState.Action>
          </>
        )}
        {tab === 'active' && (
          <>
            <BlankState.Title>There are no products, yet</BlankState.Title>
            <BlankState.Details>
              Create your first product from scratch or explore all the features and possibilities with our demos.
            </BlankState.Details>
            <BlankState.Action onClick={() => createProduct()}>Create product</BlankState.Action>
          </>
        )}
      </BlankState>

      {tab === 'active' && (
        <div className="flex flex-col">
          <span className="pb-6 text-sm font-bold">Explore with our demos</span>
          <ProductsList
            products={data?.results || []}
            isLoading={isLoading}
            lastCard={
              <Card className="bg-neutral-50 w-[240px] h-[234px] justify-center items-center m-[10px]" shadow={false}>
                <Button variant="default" onClick={openDemo}>
                  View all demo
                </Button>
              </Card>
            }
          />
        </div>
      )}
    </div>
  )
}

export default ProductsBlankState
