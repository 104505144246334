import { DenormalizedTheme, ThemeName } from '@packages/types'
import { merge, reduce } from 'lodash'

import { utils as themesUtils } from 'customizer/theme'
import barebonesTheme from 'themes/barebones/config.json'
import booklikeTheme from 'themes/booklike/config.json'

import { ThemeConfig } from './types'

export const getThemeConfig = (themeName: string | null | undefined): ThemeConfig => {
  switch (themeName) {
    case ThemeName.Barebones:
      return barebonesTheme as ThemeConfig
    case ThemeName.Booklike:
    default:
      return booklikeTheme as ThemeConfig
  }
}

export const mergeDefaultConfigWithTheme = (theme?: DenormalizedTheme) => {
  const themeConfig = getThemeConfig(theme?.name)

  return merge(
    {},
    { ...themeConfig, settings: themesUtils.reduceSettingToValues(themeConfig.settings) },
    theme || {}
  ) as unknown as DenormalizedTheme
}

export const colorKeysAsArray = (settings: ThemeConfig['settings']) => {
  return reduce(
    settings,
    (everyColors, section, key) => {
      const everyChildColors = reduce(
        section.settings,
        (childColors, { type }: { type: string }, childKey) => {
          return type === 'color' ? [...childColors, `${key}.${childKey}`] : childColors
        },
        [] as string[]
      )

      return [...everyColors, ...everyChildColors]
    },
    [] as string[]
  )
}
