import React from 'react'
import { useParams, useLocation, useHistory } from 'react-router'

import { Header, Page, SideMenu } from 'cms/layout'
import { withFlag } from 'common/users/components'

import { useFetchQuote } from '../../hooks/useFetchQuote'
import { defaultPersistenceState } from '../../utils'
import QuoteCard from './QuoteCard'
import QuoteDetailsCard from './QuoteDetailsCard'
import QuoteExpirationCard from './QuoteExpirationCard'
import QuoteHeader from './QuoteHeader'
import QuoteStoreCard from './QuoteStoreCard'
import { Button, Modal, useNavigationBlockingModal } from 'common/components'

interface QuoteProps {
  isRevision?: boolean
}

const Quote = ({ isRevision }: QuoteProps) => {
  const history = useHistory()
  const { id: quoteId } = useParams<{ id: string }>()
  const { brandName } = useParams<{ brandName?: string }>()
  const baseUrl = brandName ? `/brands/${brandName}` : ''
  const confirmLeaveModal = useNavigationBlockingModal(history, [!!isRevision])

  const location = useLocation<{ persistence?: typeof defaultPersistenceState }>()
  const persistence = location.state?.persistence

  const { quote, quoteSiblings, isLoadingQuotes, isFetchingQuotes, handleGoBack } = useFetchQuote({
    persistence,
    quoteId,
    baseUrl,
    isRevision,
  })

  return (
    <main>
      <Header />
      <SideMenu />
      <Page data-testid="quote page">
        <QuoteHeader
          quote={quote}
          isLoading={isLoadingQuotes}
          isFetching={isFetchingQuotes}
          onGoBack={handleGoBack}
          baseUrl={baseUrl}
          quoteSiblings={quoteSiblings}
        />

        <div className="flex items-start">
          {isLoadingQuotes || !quote ? (
            <QuoteCard quote={quote} isLoading={isLoadingQuotes} baseUrl={baseUrl} />
          ) : (
            quote.items?.map(({ design }, index) => {
              return (
                <QuoteCard
                  key={`${design?.id}-${index}`}
                  quote={quote}
                  design={design}
                  isLoading={isLoadingQuotes}
                  baseUrl={baseUrl}
                />
              )
            })
          )}

          <div className="flex flex-col space-y-8">
            <QuoteDetailsCard quote={quote} isLoading={isLoadingQuotes} />
            <QuoteExpirationCard quote={quote} isLoading={isLoadingQuotes} />
            <QuoteStoreCard quote={quote} isLoading={isLoadingQuotes} />
          </div>
        </div>

        {confirmLeaveModal.isVisible && (
          <Modal {...confirmLeaveModal.modalProps}>
            <Modal.Title>Unsaved revision</Modal.Title>
            <Modal.Details>
              Are you sure you want to leave without finalizing? All changes will be deleted, this action can't be
              undone
            </Modal.Details>
            <Modal.Actions>
              <Button onClick={confirmLeaveModal.close}>Stay</Button>
              <Button variant="error" onClick={confirmLeaveModal.forceNavigate}>
                Leave
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </Page>
    </main>
  )
}

export default withFlag({
  Component: Quote,
  feature: 'quote_phase_1',
})
