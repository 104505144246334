import { CustomizerProduct, NormalizedCustomizerProduct, DenormalizedProduct } from '@packages/types'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { isEqual } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { ArrayParam, BooleanParam, JsonParam, StringParam, useQueryParam, withDefault } from 'use-query-params'

import { Loader } from 'builder/common/components'
import GlobalRouterContext from 'builder/common/GlobalRouterContext'
import Page from 'builder/components/Page'
import {
  TopBar,
  TopBarBackButton,
  TopBarProductActions,
  TopBarProductName,
  TopBarSection,
  TopBarTabs,
} from 'builder/topBar/components'
import { NetworkError } from 'common/api'
import { CardTabs, IconButton, useModal, useToast, NumberFilter, BetaTag } from 'common/components'
import { ToastType } from 'common/components/toast/types'
import { customizerProductsUtils } from 'common/customizerProducts'
import { useDataTable, useDataTableQuery, useTableFilters, useTableSearch, useTableSort } from 'common/hooks/dataTable'
import { trpc } from 'common/hooks/trpc'
import { useProductService } from 'common/products'
import AddIcon from 'icons/custom/streamlinehq-interface-add-1-interface-essential-250.svg'

import { InventoryPersistence } from '../types/dataTable'
import { NoVariantsBlankState } from './blankStates'
import CombinationCard from './CombinationCard'
import { CreateVariantsModal, EditVariantsModal } from './modals'
import { VariantsForm, TooManyVariantsAlert } from './variantsForm'

const Variants = ({ match }: RouteComponentProps<{ productId: string }>) => {
  const [selectedCombinationKey, setSelectedCombinationKey] = useQueryParam('combination', StringParam)

  const persistence = useDataTableQuery<InventoryPersistence['state']>(
    {
      stock: undefined,
      filter: '',
      sortKey: undefined,
      sortOrder: undefined,
      questionAnswers: undefined,
      continueSelling: undefined,
    },
    {
      filter: withDefault(StringParam, ''),
      sortKey: StringParam,
      sortOrder: StringParam,
      //@ts-expect-error we need to return object from encode
      stock: withDefault(NumberFilter.URLParam, undefined),
      //@ts-expect-error we need to return object from encode
      committed: withDefault(NumberFilter.URLParam, undefined),
      //@ts-expect-error we need to return object from encode
      available: withDefault(NumberFilter.URLParam, undefined),
      status: withDefault(ArrayParam, []),
      various: withDefault(ArrayParam, []),
      questionAnswers: withDefault(JsonParam, undefined),
      continueSelling: withDefault(BooleanParam, undefined),
    },
    {
      combination: selectedCombinationKey,
    }
  )

  const dataTable = useDataTable(persistence, [
    useTableSearch({}),
    useTableSort({}),
    useTableFilters<InventoryPersistence['state']>({}),
  ])

  const ref = useRef(null)

  const trpcContext = trpc.useContext()
  const { data: inventoryLocation } = trpc.inventory.location.getDefault.useQuery()

  const editCombinationModal = useModal()
  const addCombinationModal = useModal()

  const [editedCombination, setEditedCombination] = useState<string[] | false>(false)
  const closeEditCombinationModal = () => {
    setEditedCombination(false)
    editCombinationModal.close()
  }
  const openEditCombinationModal = (combination: string[]) => {
    setEditedCombination(combination)
    editCombinationModal.open()
  }

  const queryClient = useQueryClient()
  const productService = useProductService()

  const productQueryKey = [...productService.fetch.queryKeys, match.params.productId, { fields: ['live'] }]

  const { openToast, openGenericErrorToast } = useToast()
  const {
    data: productData,
    isLoading: isLoadingProduct,
    remove: removeProductQuery,
  } = useQuery<DenormalizedProduct, NetworkError, [DenormalizedProduct, NormalizedCustomizerProduct] | []>(
    productQueryKey,
    async () => {
      const result = await productService.fetch(match.params.productId, { params: { fields: ['live'] } })
      return result as DenormalizedProduct
    },
    {
      select: data => (data != null ? [data, customizerProductsUtils.normalize(data.live as CustomizerProduct)] : []),
    }
  )

  const setSelectedCombination = (combination: string) => {
    dataTable.clearFilters('stock')
    dataTable.clearFilters('questionAnswers')
    dataTable.clearFilters('filter')
    dataTable.clearFilters('isNewFromPublish')
    setSelectedCombinationKey(combination)
  }

  const liveCustomizerProduct = productData?.[1]
  const product = productData?.[0]

  const { mutate: updateVariantsCombinations, isLoading: isSavingProduct } = useMutation(
    (variantsCombinations: string[][]) => productService.update(match.params.productId, { variantsCombinations }),
    {
      onSuccess: _data => {
        const changedCombinationIndex = _data.variantsCombinations.findIndex(
          (combination, i) => !isEqual(product?.variantsCombinations[i], combination)
        )

        const changedCombination = _data.variantsCombinations[changedCombinationIndex]?.join(',')

        setSelectedCombination(changedCombination ?? _data.variantsCombinations[0].join(','))

        queryClient.invalidateQueries(productQueryKey)
        trpcContext.variant.get.invalidate({
          productId: match.params.productId,
          questionIds: selectedCombinationKey?.split(',') as string[],
          customizerProductId: productData?.[0]!.live.id,
        })

        openToast('Variants were successfully created.', ToastType.success)

        setEditedCombination(false)
        addCombinationModal.close()
      },
      onError: () => {
        openGenericErrorToast('Could not create variants please try again.')

        setEditedCombination(false)
        addCombinationModal.close()
      },
    }
  )

  const { mutate: deleteVariantsCombinations, isLoading: isDeletingCombination } = useMutation(
    (variantsCombinations: string[][]) => productService.update(match.params.productId, { variantsCombinations }),
    {
      onSuccess: _data => {
        const changedCombination = _data.variantsCombinations
          .find((combination, i) => !isEqual(product?.variantsCombinations[i], combination))
          ?.join(',')

        setSelectedCombination(changedCombination ?? _data.variantsCombinations[0]?.join(','))

        queryClient.invalidateQueries(productQueryKey)
        trpcContext.variant.get.invalidate({
          productId: match.params.productId,
        })

        openToast('Variants were successfully deleted.', ToastType.success)

        closeEditCombinationModal()
        addCombinationModal.close()
      },
      onError: () => {
        openGenericErrorToast('Could not delete variants please try again.')

        closeEditCombinationModal()
        addCombinationModal.close()
      },
    }
  )

  useEffect(
    () => () => {
      removeProductQuery()
    },
    []
  )

  useEffect(() => {
    if (product?.variantsCombinations?.[0] && !selectedCombinationKey) {
      setSelectedCombination(product.variantsCombinations[0]?.join(','))
    }
  }, [product?.variantsCombinations])

  const selectedCombination = product?.variantsCombinations.find(
    combination => combination.join(',') === selectedCombinationKey
  )

  if (isLoadingProduct) return <Loader />

  return (
    <div ref={ref} className="h-screen overflow-x-auto">
      <TopBar>
        <TopBarSection className="pl-5">
          <TopBarBackButton />
          <TopBarProductName />
          <TopBarProductActions />
        </TopBarSection>
        <TopBarTabs />
      </TopBar>
      <Page variant="lg">
        <h1 className="mb-4">
          Inventory
          <BetaTag className="ml-2" />
        </h1>
        <hr className="h-[1px] w-full border-neutral-100 mb-4" />
        <div className="mt-8 flex flex-col">
          {!!liveCustomizerProduct && (
            <>
              {!!product && product.variantsCombinations.length === 0 && (
                <NoVariantsBlankState onCreateVariants={addCombinationModal.open} />
              )}
              {!!product && product.variantsCombinations.length > 0 && (
                <div className="flex">
                  <div className="flex flex-col space-y-2 mr-12 basis-64 w-64 shrink-0 pb-4">
                    <div className="font-medium mb-2 flex items-center">
                      Question combinations
                      <IconButton
                        Icon={AddIcon}
                        smallIcon
                        className="ml-auto"
                        onClick={addCombinationModal.open}
                        aria-label="Create question combination"
                      />
                    </div>
                    <CardTabs>
                      {product?.variantsCombinations.map(combination => (
                        <CombinationCard
                          key={combination.join(',')}
                          combination={combination}
                          customizerProduct={liveCustomizerProduct}
                          selected={combination.join(',') === selectedCombinationKey}
                          onClick={() => {
                            setSelectedCombination(combination.join(','))
                          }}
                          onClickChangeQuestions={() => openEditCombinationModal(combination)}
                          onClickDelete={() =>
                            deleteVariantsCombinations(
                              product.variantsCombinations.filter(
                                existingCombination => !isEqual(existingCombination, combination)
                              )
                            )
                          }
                          isDeletingCombination={isDeletingCombination}
                          productId={match.params.productId}
                          customizerProductId={product.live.id}
                        />
                      ))}
                    </CardTabs>
                  </div>
                  <div className="w-full">
                    {inventoryLocation != null && selectedCombination != null && (
                      <>
                        <TooManyVariantsAlert
                          variantsCombination={selectedCombination}
                          customizerProduct={liveCustomizerProduct}
                        />
                        <VariantsForm
                          key={selectedCombination.join('-')}
                          locationId={inventoryLocation?.id}
                          scrollParentRef={ref}
                          productId={product.id}
                          customizerProductId={product.live.id}
                          variantsCombination={selectedCombination}
                          customizerProduct={liveCustomizerProduct}
                          onChangeCombinationClick={() => openEditCombinationModal(selectedCombination)}
                          dataTable={dataTable}
                          persistence={persistence.state}
                        />
                      </>
                    )}
                  </div>
                </div>
              )}
              {addCombinationModal.isVisible && (
                <CreateVariantsModal
                  modalProps={addCombinationModal.modalProps}
                  onCreate={updateVariantsCombinations}
                  isSaving={isSavingProduct}
                  onCancelClick={addCombinationModal.close}
                  customizerProduct={liveCustomizerProduct}
                  existingCombinations={product?.variantsCombinations}
                />
              )}
              {editCombinationModal.isVisible && editedCombination && !!product && (
                <EditVariantsModal
                  modalProps={editCombinationModal.modalProps}
                  onCreate={updateVariantsCombinations}
                  isSaving={isSavingProduct}
                  onCancelClick={closeEditCombinationModal}
                  customizerProduct={liveCustomizerProduct}
                  combination={editedCombination}
                  existingCombinations={product?.variantsCombinations}
                />
              )}
            </>
          )}
        </div>
      </Page>
    </div>
  )
}

export default (props: RouteComponentProps<{ productId: string }>) => (
  <GlobalRouterContext.Provider value={{ ...props }}>
    <Variants {...props} />
  </GlobalRouterContext.Provider>
)
