import React from 'react'

import { Button } from 'common/components'

import { ThemeConfig } from '../types'
import SettingsContainer from './SettingsContainer'
import ThemeSettingSection from './ThemeSettingSection'

interface ThemeSettingsSectionsPanelProps {
  onReset: () => void
  settingSection?: string | null
  setSettingSection: (section: keyof ThemeConfig['settings']) => void
  settings: ThemeConfig['settings']
}

const ThemeSettingsSectionsPanel = ({
  onReset,
  settingSection,
  setSettingSection,
  settings,
}: ThemeSettingsSectionsPanelProps) => {
  return (
    <div className="border-solid border-r-[1px] border-neutral-75">
      <SettingsContainer>
        <div className="flex flex-col space-y-4 my-4">
          {Object.keys(settings).map(key => (
            <ThemeSettingSection
              key={key}
              selected={settingSection === key}
              section={settings[key as keyof ThemeConfig['settings']]}
              onClick={() => setSettingSection(key as keyof ThemeConfig['settings'])}
            />
          ))}
        </div>
        <div className="w-full h-[1px] shrink-0 bg-neutral-75" />
        <div className="flex flex-col p-4">
          <div className="mb-2 flex items-center font-medium">
            Reset your theme
            <Button className="ml-auto" onClick={onReset} type="button">
              Reset
            </Button>
          </div>
          <span className="text-neutral-600 text-xs">Restore your theme settings to their original value</span>
        </div>
      </SettingsContainer>
    </div>
  )
}

export default ThemeSettingsSectionsPanel
