import { AnimateLayoutChanges } from '@dnd-kit/sortable'
import React from 'react'

import { FlattenedItem, TreeItemComponentType } from './types'
import useTreeSortable from './useTreeSortable'

interface SortableItemProps<T, TElement extends HTMLElement> {
  containerId?: string
  id: string
  disabled?: boolean
  dragOverlay?: boolean
  item: FlattenedItem<T>
  indentationWidth: number
  Item: TreeItemComponentType<T, TElement>
  onToggleCollapse?: (containerId: string, itemId: string) => void
}

const animateLayoutChanges: AnimateLayoutChanges = ({ isSorting, wasDragging }) =>
  isSorting || wasDragging ? false : true

const SortableTreeItem = <T, TElement extends HTMLElement>({
  disabled,
  id,
  containerId,
  item,
  Item,
  onToggleCollapse = () => {},
  dragOverlay,
  indentationWidth,
}: SortableItemProps<T, TElement>) => {
  const { setNodeRef, listeners, isDragging, active, transform, transition, projected, willReceiveItem } =
    useTreeSortable({
      id,
      animateLayoutChanges,
    })

  return (
    <Item
      ref={disabled ? undefined : setNodeRef}
      item={item}
      active={active}
      depth={isDragging ? projected?.depth ?? item.depth : item.depth}
      dragging={isDragging}
      willReceiveItem={willReceiveItem}
      onToggleCollapse={() => onToggleCollapse(containerId!, id)}
      transition={transition}
      transform={transform}
      listeners={listeners}
      dragOverlay={dragOverlay}
      indentationWidth={indentationWidth}
    />
  )
}

export default SortableTreeItem
