import { sanitize } from '@packages/sanitizer'
import { $insertGeneratedNodes } from '@lexical/clipboard'
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html'
import { LinkNode } from '@lexical/link'
import { ListItemNode, ListNode } from '@lexical/list'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import classNames from 'classnames'
import { $getRoot, $getSelection, $setSelection, RangeSelection } from 'lexical'
import React, { useEffect, useState } from 'react'

import { PatchTextInputChangeEvent } from '../PatchTextInput'
import { LinkPlugin, ListPlugin, TextFormatPlugin } from './plugins'

export interface RichTextEditorProps {
  value: string
  placeholder?: string
  className?: string
  onChange: (e: PatchTextInputChangeEvent) => void
}

export const editorConfig = {
  namespace: 'KickflipRichTextEditor',
  theme: {
    text: {
      bold: 'font-bold',
      italic: 'italic',
      underline: 'underline',
    },
    list: {
      ul: 'list-disc',
      listitem: 'ml-[1.5em]',
    },
    link: 'text-[#3F85F7]',
  },
  onError: (error: any) => {
    throw error
  },
  nodes: [ListNode, ListItemNode, LinkNode],
}

export const RichTextEditor = ({ value, placeholder = '', className, onChange }: RichTextEditorProps) => {
  const [editor] = useLexicalComposerContext()
  const [hasFocus, setHasFocus] = useState(false)

  const handleFocus: React.FocusEventHandler<HTMLDivElement> = () => setHasFocus(true)

  const handleBlur: React.FocusEventHandler<HTMLDivElement> = () => {
    setHasFocus(false)
    editor.update(() => {
      const htmlString = sanitize($generateHtmlFromNodes(editor))
      onChange({ target: { value: htmlString } } as PatchTextInputChangeEvent)
    })
  }

  useEffect(() => {
    if (value) {
      editor.update(() => {
        const htmlString = sanitize($generateHtmlFromNodes(editor))
        if (value !== htmlString) {
          const dom = new DOMParser().parseFromString(sanitize(value), 'text/html')
          const nodes = $generateNodesFromDOM(editor, dom)
          $getRoot().clear()
          $insertGeneratedNodes(editor, nodes, $getSelection() as RangeSelection)
          $setSelection(null)
        }
      })
    }
  }, [value])

  return (
    <div
      aria-label="rich text editor"
      className={classNames(
        className,
        'relative flex flex-col rounded-lg border border-solid border-neutral-100 overflow-hidden',
        { 'outline-none border-primary-200 ring ring-primary-100': hasFocus }
      )}
    >
      <div
        className="flex items-center justify-between px-2 py-1.5 border-b border-solid border-neutral-100"
        role="toolbar"
      >
        <div className="flex">
          <TextFormatPlugin />
        </div>
        <div className="flex">
          <LinkPlugin />
          <ListPlugin />
        </div>
      </div>
      <RichTextPlugin
        contentEditable={
          <ContentEditable
            className="relative bg-white text-neutral-800 max-h-[150px] overflow-y-auto px-2 py-1.5 resize-y outline-none"
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        }
        placeholder={
          <div className="absolute text-neutral-300 px-2 py-1.5 top-[33px] pointer-events-none">{placeholder}</div>
        }
        ErrorBoundary={LexicalErrorBoundary}
      />
    </div>
  )
}

const LexicalRichTextEditor = (props: RichTextEditorProps) => {
  return (
    <LexicalComposer initialConfig={editorConfig}>
      <RichTextEditor {...props} />
    </LexicalComposer>
  )
}

export default LexicalRichTextEditor
