import { PaymentStrategy, SubscriptionStatus } from '@packages/types'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'

import { Page, SettingsHeader, SettingsSideMenu } from 'cms/layout'
import { Button, useModal } from 'common/components'
import { useCurrentUser } from 'common/users'

import { useSubscriptionService } from '../hooks'
import { ShopifyPaymentCard, StripePaymentCard, SubscriptionCard } from './cards'
import ClientCustomPlanAlert from './ClientCustomPlanAlert'
import MasterCustomPlanAlert from './MasterCustomPlanAlert'
import { CustomPlanModal, ExtendFreeTrialModal, ActivateCustomPlanModal, SubscriptionFullPageModal } from './modals'

const getPaymentDetailsMessage = (paymentStrategy: PaymentStrategy) => {
  if (paymentStrategy === PaymentStrategy.Stripe) {
    return 'Charges are automatically added to your card each month.'
  } else if (paymentStrategy === PaymentStrategy.Shopify) {
    return 'Charges are automatically added to your Shopify subscription each month.'
  }

  return ''
}

const BillingAndSubscription = () => {
  const [isEditingStripeCard, setIsEditingStripeCard] = useState(false)
  const subscriptionService = useSubscriptionService()
  const { isMasterUser, isMCZRUser } = useCurrentUser()
  const customPlanModal = useModal()
  const extendFreeTrialModal = useModal()
  const subscribeModal = useModal()

  const { data: subscription, isLoading } = useQuery(
    subscriptionService.fetchOne.queryKeys,
    subscriptionService.fetchOne
  )

  const isFreeTrialExtendable =
    subscription && [SubscriptionStatus.FreeTrial, SubscriptionStatus.Canceled].includes(subscription.status)

  const isManualOrNone =
    !!subscription?.paymentStrategy &&
    [PaymentStrategy.None, PaymentStrategy.Manual].includes(subscription.paymentStrategy)

  const canHaveCustomPlan =
    subscription &&
    [PaymentStrategy.Shopify, PaymentStrategy.Manual, PaymentStrategy.Stripe].includes(subscription.paymentStrategy)

  const willActivateCustomPlan = subscription?.plan.custom === true && subscription.status !== SubscriptionStatus.Active

  return (
    <main>
      <SettingsHeader />
      <SettingsSideMenu />

      <Page aria-label="billing page">
        <Page.Header>
          <h1 aria-label="page title">Billing & subscription</h1>
          {isMasterUser && !subscription?.proposedCustomPlan && canHaveCustomPlan && (
            <Button aria-label="create custom plan" onClick={customPlanModal.open}>
              Create custom plan
            </Button>
          )}
        </Page.Header>
        <Page.Section>
          {isMasterUser && !!subscription && !!subscription.proposedCustomPlan && (
            <MasterCustomPlanAlert handleEditModal={customPlanModal.open} subscription={subscription} />
          )}
        </Page.Section>
        <Page.Section>
          <Page.Aside
            title="Subscription"
            description="Take a look at our website to learn more about our different plans."
          >
            {isMasterUser && isFreeTrialExtendable && (
              <Button aria-label="extend free trial" onClick={extendFreeTrialModal.open} className="mt-4">
                Extend free trial
              </Button>
            )}
            {!isMCZRUser && !!subscription && !!subscription.proposedCustomPlan && (
              <ClientCustomPlanAlert subscription={subscription} />
            )}
          </Page.Aside>
          <Page.Content>
            <SubscriptionCard
              subscription={!isLoading ? subscription : undefined}
              onPlanChange={() => {
                setIsEditingStripeCard(false)
                subscribeModal.open()
              }}
            />
          </Page.Content>
        </Page.Section>
        {subscription && !isManualOrNone && (
          <>
            <Page.Separator />
            <Page.Section>
              <Page.Aside title="Payment method" description={getPaymentDetailsMessage(subscription.paymentStrategy)} />
              <Page.Content>
                {subscription.paymentStrategy === PaymentStrategy.Shopify && (
                  <ShopifyPaymentCard subscription={!isLoading ? subscription : undefined} />
                )}
                {subscription.paymentStrategy === PaymentStrategy.Stripe && (
                  <StripePaymentCard
                    subscription={!isLoading ? subscription : undefined}
                    isEditing={isEditingStripeCard}
                    setIsEditing={setIsEditingStripeCard}
                  />
                )}
              </Page.Content>
            </Page.Section>
          </>
        )}
      </Page>
      {subscription && subscribeModal.isVisible && (
        <>
          {!willActivateCustomPlan && (
            <SubscriptionFullPageModal onClose={subscribeModal.close} {...subscribeModal.modalProps} />
          )}
          {willActivateCustomPlan && (
            <ActivateCustomPlanModal onClose={subscribeModal.close} {...subscribeModal.modalProps} />
          )}
        </>
      )}
      {customPlanModal.isVisible && subscription && (
        <CustomPlanModal
          customPlanFormValues={subscription.proposedCustomPlan}
          onClose={customPlanModal.close}
          {...customPlanModal.modalProps}
        />
      )}
      {extendFreeTrialModal.isVisible && subscription && (
        <ExtendFreeTrialModal
          onClose={extendFreeTrialModal.close}
          subscription={subscription}
          {...extendFreeTrialModal.modalProps}
        />
      )}
    </main>
  )
}

export default BillingAndSubscription
