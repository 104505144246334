import classNames from 'classnames'
import React, { useContext } from 'react'

import { utils as coreUtils } from 'builder/build/core'
import GlobalRouterContext from 'builder/common/GlobalRouterContext'
import { BetaTag } from 'common/components'
import { withFlag } from 'common/users/components'

import './TopBarTabs.scss'

interface TopBarTabsProps {
  selectedTab?: string
}

interface TabProps {
  name: string | React.ReactNode
  selected: boolean
  disabled?: boolean
  handleClick: (e: React.MouseEvent) => void
}

const Tab = ({ selected, disabled = false, handleClick, name }: TabProps) => {
  return (
    <span
      className={classNames('top-bar__tab', {
        'top-bar__tab--selected': selected,
        'top-bar__tab--disabled': disabled,
      })}
      key={`tab-${name}`}
      onClick={handleClick}
      role="tab"
      aria-selected={selected}
    >
      {name}
    </span>
  )
}

const TabWithFlag = withFlag({
  Component: Tab,
  feature: 'inventory_management',
})

export const TABS_LIST = [
  { name: 'Build', path: 'builder', matchPath: ['builder'] },
  {
    name: 'Manage',
    path: 'manage/pricing',
    disabledOnDemo: true,
    matchPath: ['pricing', 'variants'],
  },
  {
    name: 'Pricing',
    path: 'manage/pricing',
    disabledOnDemo: true,
    matchPath: ['pricing', 'variants'],
  },
  { name: 'Connect', path: 'connect', disabledOnDemo: true, matchPath: ['connect'] },
]

const TopBarTabs = ({ selectedTab }: TopBarTabsProps) => {
  const { match, history } = useContext(GlobalRouterContext)
  const currentPath = match.url.split('/').pop()
  const baseUrl = `${match.params.brandName ? `/brands/${match.params.brandName}` : ''}/products/${
    match.params.productId
  }`

  const isDemo = coreUtils.isPathDemoProduct(match.path)

  const handleClick = async (path: string, isDemo: boolean, disabledOnDemo?: boolean, isActiveTab?: boolean) => {
    if (isActiveTab || (disabledOnDemo && isDemo)) return

    return history.push(`${baseUrl}/${path}`)
  }

  return (
    <div className="top-bar__tabs" role="tablist">
      <Tab
        name="Build"
        selected={currentPath != null && selectedTab ? selectedTab === 'builder' : ['builder'].includes(currentPath!)}
        handleClick={() => handleClick('builder', isDemo, false, currentPath === 'builder')}
      />
      <Tab
        name="Pricing"
        selected={currentPath != null && selectedTab ? selectedTab === 'pricing' : ['pricing'].includes(currentPath!)}
        disabled={isDemo}
        handleClick={() => handleClick('pricing', isDemo, true, currentPath === 'pricing')}
      />
      <TabWithFlag
        name={
          <>
            Inventory
            <BetaTag.Small className="ml-1" />
          </>
        }
        selected={currentPath != null && selectedTab ? selectedTab === 'variants' : ['variants'].includes(currentPath!)}
        handleClick={() => handleClick('variants', isDemo, true, currentPath === 'variants')}
        disabled={isDemo}
      />
      <Tab
        name="Connect"
        selected={currentPath != null && selectedTab ? selectedTab === 'connect' : ['connect'].includes(currentPath!)}
        disabled={isDemo}
        handleClick={() => handleClick('connect', isDemo, true, currentPath === 'connect')}
      />
    </div>
  )
}

export default TopBarTabs
