import React from 'react'

import { Button, Modal, ModalProps, UpdateCount, useToast } from 'common/components'
import { ToastType } from 'common/components/toast/types'
import { trpc } from 'common/hooks/trpc'

import { FormikVariants } from '../types'

interface ContinueSellingModalProps {
  ids: string[]
  handleClose: () => void
  modalProps: Omit<ModalProps, 'children'>
  formik: FormikVariants
  onSuccess: () => void
}

const ContinueSellingModal = ({ ids, onSuccess, handleClose, modalProps }: ContinueSellingModalProps) => {
  const { openToast } = useToast()

  const { mutate: bulkUpdate, isLoading: isSaving } = trpc.variant.bulkUpdateContinueSelling.useMutation({
    onSuccess: () => {
      onSuccess()
      openToast('Inventory was successfully saved.', ToastType.success)
    },
    onError: () => {
      openToast('Could not save inventory please try again.', ToastType.warning)
    },
  })

  const handleSave = () => {
    return bulkUpdate({ ids, continueSelling: true }, { onSettled: handleClose })
  }

  return (
    <Modal onBackdropClick={handleClose} {...modalProps}>
      <Modal.CloseButton onClick={handleClose} />
      <Modal.Title>Continue selling when out of stock</Modal.Title>
      <Modal.Details>
        All variants tagged with continue selling will still be available on your customizer even when their stock is 0
        or below.
      </Modal.Details>
      <Modal.Actions className="justify-between items-center">
        <UpdateCount text="variant" count={ids.length} />
        <div className="flex space-x-3">
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="primary" onClick={handleSave} disabled={isSaving} isLoading={isSaving}>
            Save
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  )
}

export default ContinueSellingModal
